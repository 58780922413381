import React, { Fragment, useEffect, useState } from "react";

import CardSubscription from "./CardSubscription";
import PriceCard from "./PriceCard";
import QueryTable from "./QueryTable";

import { fontCommons } from "../../Components/AccoutSetting/UpdateEmail";
import { TwoToneText } from "../../Components/AccoutSetting/UpdateEmail-old";
import { ButtonExeFilled } from "../../Components/Button";
import { cn } from "../../Components/utils";
import { useNavbar } from "../../customHook/useNavbar";
import { DeviderLineDashed } from "../AccountSetting/SettingRow";
import { QueryDetails } from "./QueryDetails";
import { CircularArrow, Toggle } from "./ToggleYear";

import Skeleton from "react-loading-skeleton";
import { token } from "../../../../Exe-api/apis";
import {
  changePlan,
  getResalaPlans,
  getUserPlan,
  sendPriceId,
} from "../../../../Exe-api/subscription-api";
import {
  featues3,
  features2,
  features4,
  features5,
  features6,
  featuresData,
  pricingOptions,
} from "../../../Pricing/jsons";
import UpdateSubscription from "../../Components/AccoutSetting/UpdateSubscription";
import ModalBaseLayout from "../../Components/Modal";
import { redirectToNewPage } from "../../utils/utils";
import SpinnerLoader from "../../../../utils/LoaderCommon";
import QueryDetailsRow from "./QueryDetailsRow";
import CustomTooltip from "../../Components/Tooltip/Tooltip";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const Subscription = () => {
  useNavbar(t("subscription"));
  const {
    i18n: { language, dir },
  } = useTranslation();

  const isArabic = language === "ar";
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [selectedPlan, setSelectedPlan] = useState(undefined);
  const [loadingPlanId, setLoadingPlanId] = useState(undefined);
  const [isChanginPlan, setIsChanginPlan] = useState(false);
  const [allPlans, setAllPlans] = useState(undefined);
  const [userPlanData, setUserPlanData] = useState(undefined);
  const [updateSubscription, setUpdateSubscription] = useState(undefined);
  const closeSubscription = () => setUpdateSubscription(undefined);

  const getPlans = async () => {
    try {
      const res = await getResalaPlans();
      if (res.Error || res.detail) {
        return;
      }
      setAllPlans(res.offers);
    } catch (err) {
      console.log(err);
    }
  };

  const getPlanUser = async () => {
    try {
      const res = await getUserPlan({ token });
      if (res.status === 401) {
        window.location.href = "/login";
        return;
      }
      if (
        res?.data?.Error ||
        res?.data?.detail ||
        res === "Something went wrong"
      ) {
        return;
      }
      setUserPlanData(res);
    } catch (err) {
      console.log("----------", err);
    }
  };

  const sendPriceIdSubscribe = async (planData) => {
    const { id } = planData;
    setLoadingPlanId(id);

    try {
      const res = await sendPriceId(planData);
      if (res.Error || res.detail) {
        return;
      }
      const { checkout_session_url } = res || {};
      if (checkout_session_url) {
        redirectToNewPage(checkout_session_url);
        return;
      }
      setUpdateSubscription(res);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingPlanId(undefined);
    }
  };

  useEffect(() => {
    if (token) {
      setIsPageLoading(true);
      getPlanUser();
      getPlans();
    }
  }, [token]);

  useEffect(() => {
    if (allPlans && userPlanData) {
      const { subscription_id, plan_duration_id } = userPlanData;
      const freqObj = pricingOptions?.find((e) => e.id === plan_duration_id);
      if (!freqObj) return;
      setSelected(freqObj);
      const curentPlan = allPlans[freqObj.val]?.find(
        (p) => p.id === subscription_id
      );
      setSelectedPlan(curentPlan);
      setIsPageLoading(false);
    }
  }, [allPlans, userPlanData]);

  const handleChangePlan = async () => {
    setIsChanginPlan(true);
    try {
      const res = await changePlan();
      if (res.Error || res.detail) {
        return;
      }
      const { checkout_session_url } = res || {};
      if (checkout_session_url) {
        redirectToNewPage(checkout_session_url);
        return;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsChanginPlan(false);
    }
  };

  // if (!allPlans || !userPlanData || !selected || !selectedPlan) {
  //   return (
  //     <div className="w-full">
  //       <div className="flex justify-center">Loading data...</div>
  //     </div>
  //   );
  // }

  const StandardQuery = formatQueryUsage(userPlanData?.standard_queries ?? {});
  const AdvancedQuery = formatQueryUsage(userPlanData?.advance_queries ?? {});
  const ImageQuery = formatQueryUsage(userPlanData?.image_query ?? {});

  return (
    <>
      <div className="w-full">
        <div className="p-[40px] w-full relative">
          {isPageLoading ? (
            <Skeleton
              containerClassName={cn(
                "absolute absolute top-[11px]",
                isArabic ? "left-[40px]" : "right-[40px]"
              )}
              width={300}
              height={18}
            />
          ) : (
            userPlanData?.billing_renew_days && (
              <TwoToneText
                className={cn(
                  "absolute top-[11px]",
                  isArabic ? "left-[40px]" : "right-[40px]"
                )}
                fontCommons={fontCommons}
                text1={t("renew_in")}
                text2={userPlanData?.billing_renew_days}
                text3={
                  userPlanData?.billing_renew_days == 1 ? t("day") : t("days")
                }
              />
            )
          )}
          <div className="flex gap-x-[50px] ">
            <CardSubscription>
              <div className="flex justify-between mb-[50px]">
                {isPageLoading ? (
                  <Skeleton height={36} width={134} />
                ) : (
                  <button className="bg-LightPrimary text-white h-[33px] px-[16px] flex items-center gap-x-[10px] rounded-[4px]">
                    <FlashIcon /> {t(userPlanData?.name)}
                  </button>
                )}
                {isPageLoading ? (
                  <Skeleton height={60} width={134} />
                ) : (
                  <div className="flex items-start text-LightPrimary">
                    <p className="text-[26px] leading-[33px] tracking-[-1px] font-medium">
                      $
                    </p>
                    <p className="text-[45px] leading-[59px] tracking-[-1px] font-bold">
                      {userPlanData?.price}
                    </p>
                    <p className="text-[14px] leading-[18px] tracking-[-1px] text-[#8C90A5] font-medium mt-[4px]">
                      {t("by_month")}
                    </p>
                  </div>
                )}
              </div>
              <div className="flex justify-between">
                {isPageLoading ? (
                  <Skeleton count={2} height={18} width={134} />
                ) : (
                  <div className="flex flex-col gap-[4px]">
                    <p
                      className={cn(fontCommons, "font-bold text-LightPrimary")}
                    >
                      {t("renew_date")}
                    </p>
                    <p className={(cn(fontCommons), "font-medium")}>
                      {userPlanData?.renewal_date}
                    </p>
                  </div>
                )}
                <div className="w-[125px]">
                  {isPageLoading ? (
                    <Skeleton height={36} width={124} />
                  ) : (
                    <ButtonExeFilled
                      isDisable={isChanginPlan}
                      text={
                        isChanginPlan ? <SpinnerLoader /> : t("change_plan")
                      }
                      height={"40px"}
                      fontColor={"#FFFFFF"}
                      hoverCss={"hover:!bg-DarkHover"}
                      onClick={handleChangePlan}
                    />
                  )}
                </div>
              </div>
            </CardSubscription>
            <CardSubscription>
              <div className="flex flex-col gap-[24px] items-center h-full">
                <QueryDetailsRow
                  isLoading={isPageLoading}
                  title={
                    <p className="flex items-center gap-x-[4px]">
                      {t("standard_queries")}
                      <CustomTooltip
                        maxWidth="430px"
                        place="top"
                        id="PendigIcon1"
                        content={`<div class="font-normal text-[12px] leading-[18px] text-LightPrimary"> ${t(
                          "basic_search"
                        )} </div>`}
                      >
                        <button id="PendigIcon1" className="flex items-center">
                          <Iicon />
                        </button>
                      </CustomTooltip>
                    </p>
                  }
                  value={StandardQuery}
                  valueBy={
                    userPlanData?.name === "Free" ? t("by_day") : t("by_month")
                  }
                />
                <QueryDetailsRow
                  isLoading={isPageLoading}
                  title={
                    <p className="flex items-center gap-x-[4px]">
                      {t("advanced_queries")}
                      <CustomTooltip
                        maxWidth="430px"
                        place="top"
                        id="PendigIcon1"
                        content={`<div class="font-normal text-[12px] leading-[18px] text-LightPrimary"> ${t(
                          "complex_search"
                        )} </div>`}
                      >
                        <button id="PendigIcon1" className="flex items-center">
                          <Iicon />
                        </button>
                      </CustomTooltip>
                    </p>
                  }
                  value={AdvancedQuery}
                  valueBy={t("by_month")}
                />
                <QueryDetailsRow
                  isLoading={isPageLoading}
                  title={
                    <p className="flex items-center gap-x-[4px]">
                      {t("image_queries")}
                      <CustomTooltip
                        maxWidth="430px"
                        place="top"
                        id="PendigIcon1"
                        content={`<div class="font-normal text-[12px] leading-[18px] text-LightPrimary"> ${t(
                          "image_gen"
                        )} </div>`}
                      >
                        <button id="PendigIcon1" className="flex items-center">
                          <Iicon />
                        </button>
                      </CustomTooltip>
                    </p>
                  }
                  value={ImageQuery}
                  valueBy={t("by_month")}
                />
              </div>
            </CardSubscription>
          </div>

          <div className="my-[80px]">
            <DeviderLineDashed />
          </div>

          {/* <div className="w-full flex justify-center items-center">
            <div className="relative">
              {isPageLoading ? (
                <Skeleton width={230} height={48} />
              ) : (
                <Toggle
                  Options={pricingOptions}
                  selected={selected}
                  setSelected={setSelected}
                />
              )}
              {!isPageLoading && (
                <div className="absolute left-full bottom-0">
                  <CircularArrow className="ml-[3px]" />
                  <div className="absolute top-[-20px] left-[42px] text-[16px] leading-[20px] font-medium text-LightPrimary whitespace-nowrap text-center min-w-[86px]">
                    {t("SaveUpTo")} <br />
                    <span className="text-[22px] leading-[28px] font-bold text-LightPrimaryActive">
                      50%
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div> */}

          <div className="w-full flex justify-center items-center">
            <div className="flex">
              {isPageLoading ? (
                <Skeleton width={230} height={48} />
              ) : (
                <Toggle
                  Options={pricingOptions}
                  selected={selected}
                  setSelected={setSelected}
                />
              )}
              {!isPageLoading && (
                <div className="flex items-center">
                  {isArabic ? (
                    <Vector className="mx-1" />
                  ) : (
                    <CircularArrow className="mx-1" />
                  )}
                  <div className="text-[16px] leading-[20px] font-medium text-LightPrimary whitespace-nowrap text-center">
                    {t("SaveUpTo")} <br />
                    <span className="text-[22px] leading-[28px] font-bold text-LightPrimaryActive">
                      50%
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-x-[22px] mt-[100px]">
            {isPageLoading ? (
              [...Array(4).keys()].map((_, idx) => (
                <Fragment key={idx}>
                  <Skeleton width={256} height={384} />
                </Fragment>
              ))
            ) : (
              <PriceCard
                isLoadingId={loadingPlanId}
                setSelectedPlan={() => {}}
                selectedPlan={selectedPlan || []}
                onSubscribePlan={sendPriceIdSubscribe}
                planFrequency={selected}
                plans={(allPlans && allPlans[selected?.val]) || []}
              />
            )}
          </div>
          <div className="rounded-lg overflow-hidden mt-[100px]">
            {isPageLoading ? (
              [...Array(4).keys()].map((_, idx) => (
                <div className="my-2" key={idx}>
                  <Skeleton containerClassName="w-full" height={65} />
                  <div className="grid grid-cols-4 gap-2">
                    <Skeleton height={40} containerClassName="w-full" />
                    <Skeleton height={40} containerClassName="w-full" />
                    <Skeleton height={40} containerClassName="w-full" />
                    <Skeleton height={40} containerClassName="w-full" />
                  </div>
                  <div className="grid grid-cols-4 gap-2">
                    <Skeleton height={40} containerClassName="w-full" />
                    <Skeleton height={40} containerClassName="w-full" />
                    <Skeleton height={40} containerClassName="w-full" />
                    <Skeleton height={40} containerClassName="w-full" />
                  </div>
                </div>
              ))
            ) : (
              <>
                <QueryTable tableData={featuresData} />
                <QueryTable onlyTitle={t("ResalaChat")} tableData={features2} />
                <QueryTable
                  onlyTitle={t("Summarization")}
                  tableData={featues3}
                />
                <QueryTable
                  onlyTitle={t("WritingAssistant")}
                  tableData={features4}
                />
                <QueryTable
                  onlyTitle={t("SocialInteractions")}
                  tableData={features5}
                />
                <QueryTable onlyTitle={t("Support")} tableData={features6} />
              </>
            )}
          </div>
        </div>
      </div>

      {/* Update Subscription */}
      <ModalBaseLayout
        isPreventOutsideClick
        title={t("plan_details")}
        isOpen={updateSubscription ? true : false}
        setClose={closeSubscription}
      >
        <UpdateSubscription
          onClose={closeSubscription}
          paymentDetails={updateSubscription}
        />
      </ModalBaseLayout>
    </>
  );
};

export default Subscription;

export const FlashIcon = (props) => {
  return (
    <svg
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Iconsax/Linear/flash">
        <path
          id="Vector"
          d="M4.56745 9.96017H6.88495V15.3602C6.88495 16.6202 7.56745 16.8752 8.39995 15.9302L14.0775 9.48017C14.775 8.69267 14.4825 8.04017 13.425 8.04017H11.1075V2.64017C11.1075 1.38017 10.425 1.12517 9.59245 2.07017L3.91495 8.52017C3.22495 9.31517 3.51745 9.96017 4.56745 9.96017Z"
          stroke="#FFFFFF"
          fill="#FFFFFF"
          strokeWidth="1.125"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export function formatQueryUsage(query) {
  return typeof query.total === "number"
    ? `${query.used}/${query.total}`
    : "Unlimited";
}

export const Iicon = (props) => (
  <svg
    {...props}
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 17C4.85775 17 1.5 13.6422 1.5 9.5C1.5 5.35775 4.85775 2 9 2C13.1422 2 16.5 5.35775 16.5 9.5C16.5 13.6422 13.1422 17 9 17ZM9 15.5C10.5913 15.5 12.1174 14.8679 13.2426 13.7426C14.3679 12.6174 15 11.0913 15 9.5C15 7.9087 14.3679 6.38258 13.2426 5.25736C12.1174 4.13214 10.5913 3.5 9 3.5C7.4087 3.5 5.88258 4.13214 4.75736 5.25736C3.63214 6.38258 3 7.9087 3 9.5C3 11.0913 3.63214 12.6174 4.75736 13.7426C5.88258 14.8679 7.4087 15.5 9 15.5ZM9 8C9.19891 8 9.38968 8.07902 9.53033 8.21967C9.67098 8.36032 9.75 8.55109 9.75 8.75V12.5C9.75 12.6989 9.67098 12.8897 9.53033 13.0303C9.38968 13.171 9.19891 13.25 9 13.25C8.80109 13.25 8.61032 13.171 8.46967 13.0303C8.32902 12.8897 8.25 12.6989 8.25 12.5V8.75C8.25 8.55109 8.32902 8.36032 8.46967 8.21967C8.61032 8.07902 8.80109 8 9 8ZM9 7.25C8.80109 7.25 8.61032 7.17098 8.46967 7.03033C8.32902 6.88968 8.25 6.69891 8.25 6.5C8.25 6.30109 8.32902 6.11032 8.46967 5.96967C8.61032 5.82902 8.80109 5.75 9 5.75C9.19891 5.75 9.38968 5.82902 9.53033 5.96967C9.67098 6.11032 9.75 6.30109 9.75 6.5C9.75 6.69891 9.67098 6.88968 9.53033 7.03033C9.38968 7.17098 9.19891 7.25 9 7.25Z"
      fill="#6D77A0"
    />
  </svg>
);

export const Vector = (props) => (
  <svg
    {...props}
    width="52"
    height="28"
    viewBox="0 0 52 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10932_246994)">
      <path
        d="M43.5158 17.3931C43.6406 17.6427 44.6386 18.0169 45.7615 18.516C46.8843 19.015 48.2566 19.3893 49.1299 19.6388C48.0071 20.3873 46.8843 21.1359 45.8862 21.7597C42.5177 23.8806 38.8997 25.3777 35.2818 25.752C31.6638 26.1262 28.0458 25.2529 25.3011 23.132C22.5564 21.1359 20.685 18.1417 19.9365 15.0227C19.1879 12.0285 19.6869 8.66004 21.6831 6.66391C22.6811 5.66584 23.9287 5.04204 25.3011 5.04204C26.5487 5.04204 27.7962 5.66584 28.6696 6.53915C29.5429 7.53721 30.0419 8.66004 30.0419 9.90763C30.1667 11.1552 29.7924 12.278 29.1686 13.4009C27.921 15.5218 25.9249 16.8941 23.6792 17.3931C21.5583 18.0169 19.3127 18.0169 17.1918 17.6427C15.1956 17.2684 13.3243 16.6446 11.7024 15.7713C10.0805 14.898 8.70819 13.8999 7.4606 12.7771C5.09019 10.6562 3.59309 8.41053 2.71978 6.41439C0.848401 2.42212 0.973159 0.0517063 0.598884 0.0517063C0.474125 0.0517063 0.349367 0.176464 0.224608 0.800257C0.224608 1.42405 0.224608 2.54688 0.598884 4.41825C1.09792 6.28963 2.09599 8.90956 4.4664 12.0285C5.71398 13.5256 7.33584 15.1475 9.5815 16.5198C11.8272 17.8922 14.5718 19.1398 17.9403 19.514C20.0612 19.7636 22.1821 19.6388 24.303 19.015C26.2991 18.3912 28.2953 17.2684 29.6676 15.5218C31.04 13.8999 31.9133 11.6542 31.6638 9.40859C31.4142 7.16294 30.1667 5.04205 28.2953 3.91922C27.2972 3.29543 26.1744 2.92115 25.0516 3.04591C23.9287 3.04591 22.8059 3.29543 21.9326 3.79446C20.0612 4.79253 18.6889 6.53915 18.0651 8.41053C16.8175 12.1533 17.566 16.0208 19.1879 19.1398C20.8098 22.2587 23.3049 24.6291 26.0496 26.0015C29.0438 27.7481 32.038 28.1224 34.7827 27.9976C37.4026 27.7481 39.7731 27.1243 41.7692 26.251C43.7653 25.3777 45.3872 24.5044 46.8843 23.6311C49.0052 22.2587 49.629 21.8844 50.128 21.3854C50.0033 23.132 49.7537 26.3758 50.128 26.9995C51.5004 24.7539 51.8746 21.1359 52.1241 18.8902C51.7499 18.6407 46.2605 17.3931 43.5158 17.3931Z"
        fill="#1678F2"
      />
    </g>
    <defs>
      <clipPath id="clip0_10932_246994">
        <rect
          width="27.5716"
          height="51.65"
          fill="white"
          transform="translate(0.349609 28) rotate(-90)"
        />
      </clipPath>
    </defs>
  </svg>
);
