import { useState, useEffect } from "react";

const useSidebar = () => {
  // Initialize state from localStorage or default to false
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const saved = localStorage.getItem("sidebarCollapsed");
    return saved === "true" ? true : false;
  });

  // Update localStorage when isCollapsed changes
  useEffect(() => {
    localStorage.setItem("sidebarCollapsed", isCollapsed);
  }, [isCollapsed]);

  const toggleSidebar = () => setIsCollapsed(!isCollapsed);

  return {
    isCollapsed,
    toggleSidebar
  };
};

export default useSidebar;
