import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import AppleLogin from "react-apple-login";
import ReactFacebookLogin from "react-facebook-login";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingGIF from "./utils/LoaderRing.gif";

import GradientBg from "../../Components/GradientBg";
import {
  checkFbAccExistById,
  emailCheck,
  loginUser,
  loginWithApple,
  loginWithFacebook,
  loginWithGoogle,
} from "../../api/auth/auth";
import {
  APPLE_CLIENT_ID,
  APPLE_REDIRECT_URI,
  FACEBOOK_APP_ID,
  REDIRECTION_URL,
} from "../../config";
import {
  CloseEyeIcon,
  EyeIcon,
} from "./Components/AccoutSetting/UpdatePassword";
import InputField, { ErrorMsg } from "./Components/InputField";
import {
  AppleIcon,
  FacebookIcon,
  GoogleIcon,
  ResalaLogo,
  Socialbutton,
} from "./Icons/Icons";
import SpinnerLoader from "../../utils/LoaderCommon";
import { cn } from "./Components/utils";
import useFacebookSdk from "../../hooks/useFacebookSdk";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { ArabicLogo } from "./layout/Sidebar";
import ReCAPTCHA from "react-google-recaptcha";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import LanguageSelect, { TransparentLangSelect } from "./Components/LangSelect";
import { useDetectCountry } from "./customHook/useDetectCountry";

// fr = From
// el = Extension Login

export const langEnum = {
  en: 1,
  ar: 2,
  es: 3,
  zh: 4,
  hi: 5,
  pt: 6,
  ru: 7,
};

const Login = () => {
  const recaptchaRef = useRef(null);
  const [searchParams] = useSearchParams();

  const isFromExtension = searchParams.get("fr") === "el";
  const redirectTo = searchParams.get("p");
  const langExe = searchParams.get("lang");
  const userBrowserCountry = useDetectCountry();

  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [isPassShow, setIsPassShow] = useState(false);
  const [isSplashLoading, setIsSplashLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const ErrInitial = {
    email: undefined,
    password: undefined,
  };
  const [errorMsg, setErrorMsg] = useState(ErrInitial);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const initialCaptchMeta = {
    errorCaptch: false,
    isShowCaptcha: false,
    captchCode: "",
    reloadCaptcha: 0,
  };
  const [captchMeta, setCaptchMeta] = useState(initialCaptchMeta);

  const {
    i18n: { language, dir, changeLanguage },
  } = useTranslation();
  const isArabic = language === "ar";

  useEffect(() => {
    changeLanguage(langExe ?? language);
    return () => {
      changeLanguage(localStorage.getItem("lang"));
    };
  }, []);

  // useEffect(() => {
  //   document.documentElement.dir = dir(language);
  // }, []);

  const isSdkLoaded = useFacebookSdk();

  useEffect(() => {
    setCaptchMeta(initialCaptchMeta);
  }, [currentStep]);

  const inValidEmail = (email) => {
    const regex = /^[\w\.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return regex.test(email) ? false : true;
  };

  useEffect(() => {
    if (captchMeta.isShowCaptcha) {
      loadCaptchaEnginge(6, "Transparent");
    }
  }, [captchMeta.isShowCaptcha, captchMeta.reloadCaptcha]);

  useEffect(() => {
    if (captchMeta.isShowCaptcha) {
      const context = document.getElementById("canv");
      if (context) {
        // context.classList.add('my-custom-captcha');
        const parentDiv = document.createElement("div");
        // Add a class name to the parent div
        parentDiv.className = "my-custom-captcha";
        // Insert the new parent div before the child div in the DOM
        context.parentNode.insertBefore(parentDiv, context);
        // Move the child div inside the new parent div
        parentDiv.appendChild(context);
      }
    }
  }, [captchMeta.isShowCaptcha]);

  const handleContinue = async (e) => {
    e.preventDefault();
    if (currentStep === 1) {
      if (captchMeta.isShowCaptcha && !validateCaptcha(captchMeta.captchCode)) {
        setCaptchMeta((prev) => ({
          ...prev,
          captchCode: "",
          errorCaptch: true,
        }));
        return;
      }
      setIsLoading(true);
      try {
        const res = await emailCheck({
          email: loginData.email.trim(),
          interface_language: langEnum[langExe ?? language],
        });
        if (res.data?.Error) {
          if (res.data?.Message.includes("notregister")) {
            setErrorMsg({
              ...errorMsg,
              email: (
                <p className="">
                  {t("try_diff_account")}{" "}
                  <span
                    className="underline cursor-pointer"
                    onClick={() => navigate("/register")}
                  >
                    {t("new_account")}
                  </span>
                </p>
              ),
            });
            setCaptchMeta((prev) => ({
              ...prev,
              captchCode: "",
              reloadCaptcha: prev.reloadCaptcha + 1,
              isShowCaptcha: true,
            }));
          } else {
            // setEmailError(data);
            setErrorMsg({ ...errorMsg, email: res.data.Message });
          }

          return;
        }
        setCurrentStep(2);
      } catch (error) {
        console.log("err");
        setCaptchMeta((prev) => ({
          ...prev,
          captchCode: "",
          reloadCaptcha: prev.reloadCaptcha + 1,
          isShowCaptcha: true,
        }));
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        if (
          captchMeta.isShowCaptcha &&
          !validateCaptcha(captchMeta.captchCode)
        ) {
          setCaptchMeta((prev) => ({
            ...prev,
            captchCode: "",
            errorCaptch: true,
          }));
          return;
        }
        setIsLoading(true);
        const res = await loginUser({
          email: loginData.email.trim(),
          password: loginData.password,
          interface_language: langEnum[langExe ?? language],
        });
        if (res.data?.Error) {
          setIsLoading(false);
          setErrorMsg({ ...errorMsg, password: res.data.Message });
          setCaptchMeta((prev) => ({
            ...prev,
            captchCode: "",
            reloadCaptcha: prev.reloadCaptcha + 1,
            isShowCaptcha: true,
          }));
          return;
        }
        const { access_token } = res.Result || {};
        sendTokenToExe(access_token);
        localStorage.setItem("token", JSON.stringify(access_token));
        // navigate(`profile/`);
        if (redirectTo) {
          window.location.href = `/${redirectTo}`;
        } else {
          window.location.href = "profile";
        }
        // setIsLoading(false);

        // navigate(`/auth/${access_token}`);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setCaptchMeta((prev) => ({
          ...prev,
          captchCode: "",
          reloadCaptcha: prev.reloadCaptcha + 1,
          isShowCaptcha: true,
        }));
      }
    }
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${tokenResponse.access_token}`
        )
        .then((res) => {
          const message = {
            type: "GOOGLE_LOGIN",
            data: {
              ...res.data,
              interface_language: langExe,
              token: tokenResponse.access_token,
            },
          };
          setIsLoading(true);
          sendMessage(message);
        })
        .catch((err) => console.log(err));
    },
    onError: () => {
      setIsLoading(false);
      console.log("Login Failed");
    },
  });

  const responseFacebook = (response) => {
    if (response.status === "unknown") return;
    const message = {
      type: "FACEBOOK_LOGIN",
      data: { interface_language: langExe, ...response },
    };
    sendMessage(message);
  };

  const responseApple = (response) => {
    if (response.error) return;
    const message = {
      type: "APPLE_LOGIN",
      data: { interface_language: langExe, ...response },
    };
    sendMessage(message);
  };

  const sendMessage = (message) => {
    if (isFromExtension) {
      switch (message.type) {
        case "FACEBOOK_LOGIN":
          const { email } = message.data;
          if (!email) {
            checkFBAlreadyLinked(message.data, isFromExtension);
          } else {
            window.postMessage(message, "*");
          }
          break;
        default:
          setIsSplashLoading(true);
          window.postMessage(message, "*");
      }
      // navigate("/");
    } else {
      switch (message.type) {
        case "GOOGLE_LOGIN":
          if (message?.data) googleLogin(message.data);
          break;
        case "APPLE_LOGIN":
          appleLogin(message.data);
          break;
        case "FACEBOOK_LOGIN":
          const { email } = message.data;
          if (email) {
            facebookLogin(message.data);
          } else {
            checkFBAlreadyLinked(message.data);
          }
          break;
        default:
      }
    }
  };

  const checkFBAlreadyLinked = async (fbMetaData, fromExe = false) => {
    const { id } = fbMetaData;
    setIsLoading(true);
    try {
      const res = await checkFbAccExistById({
        facebook_id: id,
      });
      if (res.Error || res.detail) {
        console.log(res);
        return;
      }
      if (res.Result.is_exist) {
        setIsLoading(false);
        if (fromExe) {
          window.postMessage(
            { type: "FACEBOOK_LOGIN", data: { ...fbMetaData } },
            "*"
          );
        } else {
          facebookLogin(fbMetaData);
        }
      } else {
        setIsLoading(false);
        localStorage.setItem("fb_meta", JSON.stringify(fbMetaData));
        if (fromExe) {
          navigate(`/email-connect?fr=el`);
        } else {
          navigate(`/email-connect`);
        }
      }

      // navigate(`/auth/${access_token}`);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const sendTokenToExe = (access_token) => {
    window.postMessage({ type: "TOKEN_PASS", access_token: access_token }, "*");
  };

  const googleLogin = async (data) => {
    const { email, id, given_name, family_name, picture } = data;
    const payload = {
      email: email,
      google_id: id,
      first_name: given_name,
      last_name: family_name,
      profile_picture: picture,
      interface_language: langEnum[langExe ?? language],
      country_name: userBrowserCountry ?? "US",
    };

    try {
      const res = await loginWithGoogle(payload);
      if (res.Error || res.detail) {
        console.log(res);
        return;
      }
      setIsLoading(true);
      const { access_token } = res.Result || {};
      sendTokenToExe(access_token);
      localStorage.setItem("token", JSON.stringify(access_token));
      // navigate(`profile/`);
      if (redirectTo) {
        window.location.href = `/${redirectTo}`;
      } else {
        window.location.href = "profile";
      }
      // navigate(`/auth/${access_token}`);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const facebookLogin = async (data) => {
    const { name, picture, email, userID } = data;
    const payload = {
      email: email,
      name: name,
      facebook_id: userID,
      interface_language: langEnum[langExe ?? language],
      country_name: userBrowserCountry ?? "US",
      profile_picture: picture.data.url,
    };
    try {
      const res = await loginWithFacebook(payload);
      if (res.Error || res.detail) {
        console.log(res);
        return;
      }
      setIsLoading(true);
      const { access_token } = res.Result || {};
      sendTokenToExe(access_token);
      localStorage.setItem("token", JSON.stringify(access_token));
      // navigate(`profile/`);
      if (redirectTo) {
        window.location.href = `/${redirectTo}`;
      } else {
        window.location.href = "profile";
      }
      // navigate(`/auth/${access_token}`);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const appleLogin = async (data) => {
    const { code, id_token, state } = data.authorization;
    const userData = data;
    const payload = {
      code: code,
      token: id_token,
      interface_language: langEnum[langExe ?? language],
      country_name: userBrowserCountry ?? "US",
      ...(userData?.user !== undefined && {
        first_name: userData.user.name.firstName,
      }),
      ...(userData?.user !== undefined && {
        last_name: userData.user.name.lastName,
      }),
    };
    try {
      const res = await loginWithApple(payload);
      if (res.Error || res.detail) {
        console.log(res);
        return;
      }
      setIsLoading(true);
      const { access_token } = res.Result || {};
      sendTokenToExe(access_token);
      localStorage.setItem("token", JSON.stringify(access_token));
      if (redirectTo) {
        window.location.href = `/${redirectTo}`;
      } else {
        window.location.href = "profile";
      }
      // navigate(`profile/`);
      // navigate(`/auth/${access_token}`);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // window.addEventListener("beforeunload", () => {
  //   sendMessage("popupClosed");
  // });

  const handleChange = (e) => {
    setErrorMsg(ErrInitial);
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleOnChangeCaptcha = (e) => {
    setCaptchMeta((prev) => ({ ...prev, errorCaptch: false }));
    if (e.target.value.length < 7) {
      setCaptchMeta((prev) => ({ ...prev, captchCode: e.target.value }));
    }
  };

  const isDisable =
    isLoading || currentStep === 1
      ? loginData.email?.length === 0 ||
        isLoading ||
        inValidEmail(loginData.email.trim())
      : currentStep === 2 && loginData.password?.length === 0;

  if (!isSdkLoaded) return null;

  return (
    <>
      <div
        className={cn(
          "hidden fixed bg-opacity-30 bg-white backdrop-blur-[3px] w-full h-screen z-30",
          {
            block: isSplashLoading,
          }
        )}
      >
        <div className="flex justify-center items-center w-full h-full opacity-100 flex-col">
          <img className="w-[80px]" src={LoadingGIF} alt="loader" />
          <p className="text-[16px] text-LightPrimary leading-[20px] mt-[20px]">
            {t("sign_in_wait")}
          </p>
        </div>
      </div>
      <GradientBg>
        <div className="w-full flex justify-center items-center min-h-screen">
          {/* SECTION 1 */}
          <div
            style={{ boxShadow: "0px 4px 60px 0px #3C425714" }}
            className="w-[600px] h-fit rounded-[20px] bg-white py-[30px] px-[60px] flex flex-col items-center"
          >
            <ResalaLogo
              onClick={() => navigate("/")}
              className={cn(
                "min-h-[40px] cursor-pointer",
                !isArabic ? "block" : "hidden"
              )}
            />
            <ArabicLogo
              onClick={() => navigate("/")}
              className={cn(
                "min-h-[40px] cursor-pointer",
                isArabic ? "block" : "hidden"
              )}
            />
            <p className="mt-[30px] text-LightPrimary text-center text-[22px] font-bold">
              {t("login_resala")}
            </p>

            {!isFromExtension && (
              <>
                <div className="mt-[30px] text-LightPrimary text-center text-[22px] font-bold w-full">
                  <form onSubmit={handleContinue}>
                    <div className="w-full text-left">
                      <InputField
                        name="email"
                        type={"text"}
                        floatLabel={t("email_address")}
                        isFilled={loginData?.email?.length > 0}
                        className={"mb-16px"}
                        inputCss="px-[18px] py-[15px]"
                        value={loginData.email}
                        isDisabled={currentStep > 1}
                        onChange={handleChange}
                        onBlur={() => {
                          if (inValidEmail(loginData.email.trim())) {
                            setErrorMsg((prev) => ({
                              ...prev,
                              email: t("enter_valid_email"),
                            }));
                          }
                        }}
                        // placeholder={t("email_address")}
                        error={errorMsg.email}
                        suffix={
                          currentStep === 2 && (
                            <div
                              className="min-w-25px text-LightPrimaryActivefont-normal"
                              onClick={() => {
                                setCurrentStep(1);
                              }}
                            >
                              {t("edit")}
                            </div>
                          )
                        }
                      />
                    </div>
                  </form>
                  {/* {currentStep === 1 && (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LdYqVMpAAAAADSgGLII2QCemJ9Vgoxe4zus0Otl"
                      onChange={(value) => {
                        console.log(
                          "--- value Login.js [Line-no 66] ---",
                          value
                        );
                        // setRecaptch(value);
                      }}
                      className="mt-[16px] h-[78px]"
                      onExpired={() => {
                        // setRecaptch("");
                        recaptchaRef.current.reset();
                      }}
                    />
                  )} */}
                </div>

                {currentStep === 2 && (
                  <>
                    <div className="w-full">
                      <form onSubmit={handleContinue}>
                        <InputField
                          name="password"
                          onChange={handleChange}
                          className={"mt-[16px]"}
                          value={loginData.password}
                          inputCss="px-[18px] py-[15px]"
                          // placeholder={t("password")}
                          floatLabel={t("password")}
                          isFilled={loginData?.password?.length > 0}
                          type={isPassShow ? "text" : "password"}
                          error={errorMsg.password}
                          suffix={
                            <div
                              onClick={() => setIsPassShow((prev) => !prev)}
                              className="min-w-[25px]"
                            >
                              {isPassShow ? <CloseEyeIcon /> : <EyeIcon />}
                            </div>
                          }
                        />
                      </form>
                    </div>

                    <button
                      dir={isArabic ? "rtr" : "ltr"}
                      type="button"
                      className={cn(
                        `flex justify-end mt-[22px] bg-transparent text-[#1678F2] w-full mb-[24px] rounded-[6px] px-[1] py-[5px] text-[14px] font-medium hover:opacity-90 disabled:opacity-50 `,
                        {
                          "justify-start": isArabic,
                          "mt-[0px] mb-[8px]": currentStep === 2,
                        }
                      )}
                      style={{ cursor: "unset" }}
                      onClick={() => navigate("/forgot-password")}
                    >
                      <span
                        className={"text-[#1678F2] font-medium cursor-pointer"}
                      >
                        {t("forget_password")}
                      </span>
                    </button>
                  </>
                )}

                <div
                  className={cn(
                    "captcha-web w-full",
                    isArabic ? "rtl" : "ltr",
                    captchMeta.isShowCaptcha ? "block" : "hidden"
                  )}
                >
                  <LoadCanvasTemplate
                    reloadText={`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"> <path d="M22 12.5C22 18.02 17.52 22.5 12 22.5C6.48 22.5 3.11 16.94 3.11 16.94M3.11 16.94H7.63M3.11 16.94V21.94M2 12.5C2 6.98 6.44 2.5 12 2.5C18.67 2.5 22 8.06 22 8.06M22 8.06V3.06M22 8.06H17.56" stroke="var(--color-resala-primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>`}
                    reloadColor="red"
                  />
                  <div className="relative pt-[12px] w-full">
                    <InputField
                      placeholder={t("enter_captch")}
                      inputCss={"w-full px-[18px] py-[15px]"}
                      inputContainerCss={"w-full flex flex-col "}
                      className={"w-full"}
                      value={captchMeta.captchCode}
                      onChange={(e) => handleOnChangeCaptcha(e)}
                      // className="h-40px w-full mt-12px border-1px py-10px text-14px border-gray rounded-7px outline-none focus-within:outline-none shadow-none focus:outline-none pl-14px"
                    />
                    {captchMeta.errorCaptch && (
                      <ErrorMsg
                        errorMsg={t("invalid_captcha")}
                        dir={isArabic ? "rtl" : "ltr"}
                      />
                    )}
                  </div>
                </div>

                <button
                  disabled={
                    (captchMeta.isShowCaptcha &&
                      captchMeta.captchCode.length === 0) ||
                    isDisable ||
                    isLoading
                  }
                  onClick={handleContinue}
                  className="mt-[16px] bg-[#1678F2] min-h-[52px] h-[52px] flex justify-center items-center w-full text-white rounded-[6px] hover:!bg-DarkHover"
                >
                  <p className="text-[16px] font-bold leading-[16px]">
                    {isLoading ? <SpinnerLoader /> : t("continue")}
                  </p>
                </button>
                <button
                  onClick={() => navigate("/register")}
                  className={cn(
                    "text-[14px] text-[#6D77A0] font-medium mt-[24px] mb-[30px]",
                    {
                      "mb-[0px]": currentStep === 2,
                    }
                  )}
                >
                  {t("dont_have_account")}
                  <span className="text-[#1678F2] font-bold">
                    {" "}
                    {t("sign_up")}
                  </span>
                </button>
              </>
            )}

            {currentStep === 1 && (
              <>
                {/* SEPERATOR LINE */}
                {!isFromExtension && (
                  <div className="h-[1px] bg-LightStrokPrimary w-full min-h-[1px] relative">
                    <p
                      style={{
                        transform: "translate(-50%)",
                      }}
                      className="absolute top-[-8px] left-1/2 text-[14px] leading-[16px] font-bold text-[#6D77A0] px-[6px] bg-white w-fit"
                    >
                      {t("or")}
                    </p>
                  </div>
                )}

                {/* SECTION 2 */}
                <div className="mt-[32px] flex flex-col gap-[12px] w-full">
                  {/* GOOGLE LOGIN */}
                  <Socialbutton
                    dir={isArabic ? "rtl" : "ltr"}
                    className="socialLogin hover:bg-LightBgPrimary hover:text-LightPrimary hover:border-[#B1BED2]"
                    icon={<GoogleIcon />}
                    text={t("continue_with_google")}
                    onClick={() => login()}
                  />

                  {/* FACEBOOK LOGIN */}
                  <ReactFacebookLogin
                    buttonStyle={{ direction: isArabic ? "rtl" : "ltr" }}
                    cssClass={cn(
                      "socialLogin px-[15px] py-[14px] text-[14px] leading-[16px] text-[#6D77A0] border text-[#6D77A0] border-LightStrokPrimary flex gap-x-[8px] justify-start items-center w-full rounded-[6px] hover:bg-LightBgPrimary hover:text-LightPrimary hover:border-[#B1BED2]"
                    )}
                    appId={FACEBOOK_APP_ID}
                    autoLoad={false}
                    icon={<FacebookIcon />}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    textButton={t("continue_with_facebook")}
                  />

                  {/* APPLE LOGIN */}
                  <AppleLogin
                    scope="email name"
                    state="origin:web"
                    clientId={APPLE_CLIENT_ID}
                    redirectURI={APPLE_REDIRECT_URI}
                    responseType="code id_token"
                    responseMode="form_post query fragment"
                    usePopup={true}
                    callback={responseApple}
                    render={(props) => (
                      <Socialbutton
                        {...props}
                        dir={isArabic ? "rtl" : "ltr"}
                        className="socialLogin hover:bg-LightBgPrimary hover:text-LightPrimary hover:border-[#B1BED2]"
                        icon={<AppleIcon />}
                        text={t("continue_with_apple")}
                      />
                    )}
                  />
                </div>
                {currentStep < 2 && !isFromExtension && (
                  <div
                    dir={isArabic ? "rtl" : "ltr"}
                    className={cn("w-full flex justify-end mt-[16px]")}
                  >
                    <TransparentLangSelect />
                  </div>
                )}
              </>
            )}
            <Footer />
          </div>
        </div>
      </GradientBg>
    </>
  );
};

export default Login;

export const Footer = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const isArabic = language === "ar";

  return (
    <div dir={isArabic ? "rtl" : "ltr"} className="flex flex-col mt-[40px]">
      <p
        className="text-[14px] text-center"
        style={{ lineHeight: "20px", color: "#6D77A0" }}
      >
        {t("footer_txt_1")}{" "}
        <span className="text-LightPrimaryActive underline">
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            {t("privacy_policy")}
          </a>
        </span>{" "}
        {t("and")}{" "}
        <span className="text-LightPrimaryActive underline">
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            {t("terms_condition")}
          </a>
        </span>{" "}
        <br />
        {t("apply_txt")}.
      </p>
      <div className="flex gap-x-[5px] justify-center items-center text-LightPrimaryActive text-[14px] mt-[12px] text-center">
        <a
          href={`${REDIRECTION_URL}/terms${
            language === "ar" ? "?lang=ar" : ""
          }`}
          target="_blank"
          rel="noreferrer"
        >
          <p className="underline cursor-pointer">{t("terms_condition")}</p>
        </a>
        <DotSvg />
        <a
          href={`${REDIRECTION_URL}/privacy${
            language === "ar" ? "?lang=ar" : ""
          }`}
          target="_blank"
          rel="noreferrer"
        >
          <p className="underline cursor-pointer">{t("privacy_policy")}</p>
        </a>
        <DotSvg />
        <a
          href={`${REDIRECTION_URL}/privacy${
            language === "ar" ? "?lang=ar#ccpa" : "#ccpa"
          }`}
          target="_blank"
          rel="noreferrer"
        >
          <p className="underline cursor-pointer">{t("ca_privacy_policy")}</p>
        </a>
      </div>
    </div>
  );
};

export const DotSvg = (props) => (
  <svg
    {...props}
    width="5"
    height="4"
    viewBox="0 0 5 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2.5" cy="2" r="2" fill="#D9D9D9" />
  </svg>
);
