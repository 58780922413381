import axios from "axios";

import { commonHeaders, token } from "./apis";
import { BASE_URL } from "../config";

export const getReferHistory = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/reference/history`, {
      headers: { Authorization: token, ...commonHeaders },
    });
    return res.data.Result;
  } catch (error) {
    if (error?.response?.status === 401) {
      window.location.href = "/login";
    }
    return error?.response?.data || "Something went wrong";
  }
};

export const sendReferMail = async (payload) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/reference/send_referral_link`,
      payload,
      {
        headers: { Authorization: token, ...commonHeaders },
      }
    );
    return res.data.Result;
  } catch (error) {
    if (error?.response?.status === 401) {
      window.location.href = "/login";
    }
    return error?.response?.data || "Something went wrong";
  }
};
