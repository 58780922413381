import React, { useContext, useState } from "react";
import InputField from "../../Components/InputField";
import Textarea from "../../Components/Textarea";
import { ButtonExeFilled } from "../../Components/Button";
import {
  ModalTitle,
  SuccessIcon,
} from "../../Components/AccoutSetting/DeleteAccount";
import { cn } from "../../Components/utils";
import { fontCommons } from "../../Components/AccoutSetting/UpdateEmail";
import { submitRequest } from "../../../../Exe-api/support-api";
import SpinnerLoader from "../../../../utils/LoaderCommon";
import ProfileContext from "../../../../context/profileContext";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const GetInTouch = ({ onClose }) => {
  const { userData } = useContext(ProfileContext);
  const {
    i18n: { language },
  } = useTranslation();
  const isArabic = language === "ar";

  const initial = {
    name: "",
    email: userData?.email || "",
    msg: "",
  };

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(initial);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    name: null,
    email: null,
    msg: null,
  });

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateForm = (formData) => {
    const errors = {};

    if (formData.name.length === 0) {
      errors.name = "nameRequired";
    }
    if (formData.email.length === 0) {
      errors.email = "emailRequired";
    } else if (!validateEmail(formData.email)) {
      errors.email = "validGetInTouchEmail";
    }
    if (formData.msg.trim().length === 0) {
      errors.msg = "messageRequired";
    }

    return errors;
  };

  const onSubmit = async () => {
    const errors = validateForm(formData);
    if (Object.keys(errors).length > 0) {
      setErrorMsg(errors);
    } else {
      setIsLoading(true);
      try {
        const res = await submitRequest({
          name: formData.name,
          email: formData.email,
          message: formData.msg,
        });
        if (res.Error) {
          setIsLoading(false);
          return;
        }
        setStep(2);
        console.log(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleChange = (e) => {
    setErrorMsg({ ...errorMsg, [e.target.name]: null });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="w-[600px]">
      {step === 1 && (
        <>
          <ModalTitle title={t("get_in_touch")} />
          <InputField
            name={"name"}
            label={t("Name")}
            isDisabled={isLoading}
            placeholder={t("enter_name")}
            value={formData.name}
            error={t(errorMsg.name ?? "")}
            onChange={handleChange}
          />
          <InputField
            name="email"
            className="my-[16px]"
            isDisabled={true}
            placeholder={t("enter_email")}
            label={t("PersonalDataTitle1")}
            inputCss={cn(isArabic ? "text-right" : "text-left")}
            value={formData.email}
            error={t(errorMsg.email ?? "")}
            onChange={handleChange}
          />
          <Textarea
            name="msg"
            label={t("message")}
            disabled={isLoading}
            value={formData.msg}
            error={t(errorMsg.msg ?? "")}
            placeholder={t("share_feedback")}
            onChange={handleChange}
          />
          <ButtonExeFilled
            height={40}
            isDisable={isLoading}
            text={isLoading ? <SpinnerLoader /> : t("submit")}
            fontColor={"#FFFFFF"}
            onClick={onSubmit}
            hoverCss={"hover:!bg-DarkHover mt-[20px]"}
          />
        </>
      )}
      {step === 2 && (
        <>
          <div className="flex justify-center items-center w-full">
            <SuccessIcon className="w-[100px] h-[100px]" />
          </div>
          <p className="my-[20px] text-[24px] leading-[32px] tracking-[-1px] font-bold text-center text-LightPrimary">
            {t("success")}
          </p>
          <p
            className={cn(
              fontCommons,
              "mt-[20px] text-center leading-[24px] font-medium text-[16px]"
            )}
          >
            {t("thanks_reaching")}
          </p>
          <p
            className={cn(
              fontCommons,
              "mt-[20px] text-center leading-[24px] font-medium text-[16px] px-[45px]"
            )}
          >
            {t("reach_asap")}
          </p>
          <p
            className={cn(
              fontCommons,
              "mt-[20px] text-center leading-[24px] font-medium text-[16px] px-[10px] mb-[40px]"
            )}
          >
            {t("alternative_email")}{" "}
            <a
              className="text-LightPrimaryActive"
              href="mailto:support@resala.ai"
            >
              support@resala.ai{" "}
            </a>
            {t("call_at")}{" "}
            <a
              dir="ltr"
              className="text-LightPrimaryActive"
              href="tel:+14046470752"
            >
              {isArabic && "."}+1 (404) 647-0752{!isArabic && "."}
            </a>
          </p>
          <ButtonExeFilled
            height={40}
            text={t("done")}
            fontColor={"#FFFFFF"}
            hoverCss={"hover:!bg-DarkHover"}
            onClick={onClose}
          />
        </>
      )}
    </div>
  );
};

export default GetInTouch;
