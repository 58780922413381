import { RadioGroup } from "@headlessui/react";
import { t } from "i18next";
import React from "react";

const ToggleV2 = ({ Options, selected, setSelected }) => {

  return (
    <RadioGroup
      value={selected}
      onChange={setSelected}
      className="w-fit p-1 bg-[#F3F4F8] rounded-md inline-flex items-center"
    >
      <RadioGroup.Label className="sr-only">Pricing Plan</RadioGroup.Label>
      {Options.map((option) => (
        <RadioGroup.Option
          key={option.name}
          value={option}
          className={({ active, checked }) =>
            `w-fit px-[12px] py-[6px] ${
              selected.id === option.id ? "bg-white shadow" : ""
            } rounded justify-center items-center gap-1.5 flex cursor-pointer focus:outline-none`
          }
        >
          {({ active, checked }) => (
            <>
              <button
                className={`text-[12px] font-bold ${
                  selected.id === option.id
                    ? "text-LightPrimary "
                    : "text-LightTextParagraph"
                }`}
              >
                {t(option.name)}
              </button>
            </>
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

export default ToggleV2;
