import { t } from "i18next";
import React, { useState } from "react";
import QueryTableMobile from "../../Components/QueryTableMobile";
import ToggleV2 from "../../Components/ToggleV2";
import { CircularArrow, Toggle } from "../../Components/ToggleYear";
import PriceCardMobile from "./MobilePriceCard";
import {
  featues3,
  features2,
  features4,
  features5,
  features6,
  featuresData,
  pricingOptions,
} from "./jsons";
import Skeleton from "react-loading-skeleton";

const MobileViewContent = ({
  activeSubscription,
  selected,
  setSelected,
  selectedPlan,
  setSelectedPlan,
  allPlans,
}) => {
  const [selectedPlanMobile, setSelectedPlanMobile] = useState(
    selected ?? pricingOptions[0]
  );

  let rangeType = allPlans ? allPlans["monthly"] : [];
  if (selected && allPlans) {
    rangeType = allPlans[selected?.val] || [];
  }

  const currentPlanCard = rangeType
    ? rangeType.filter((itm) => itm.id === selectedPlanMobile.id)
    : [];

  return (
    <>
      {/* {TOGGLE} */}
      <div className="w-full flex justify-center items-center mt-[56px]">
        {/* MONTHLY YEARLY */}
        <div className="relative">
          <Toggle
            Options={pricingOptions}
            selected={selected ?? pricingOptions[0]}
            setSelected={setSelected}
          />
          <div className="absolute left-full bottom-0">
            <CircularArrow className="ml-[3px] w-[28px]" />
            <div className="absolute top-[-13px] left-[24px] text-[12px] leading-[16px] font-medium text-LightPrimary whitespace-nowrap text-center">
              {t("SaveUpTo")} <br />
              <span className="text-[18px] leading-[24px] font-bold text-LightPrimaryActive">
                50%
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* PLAN TOGGLE */}
      <div className="w-full flex justify-center items-center mt-[40px]">
        <ToggleV2
          Options={(allPlans && allPlans["monthly"]) || []}
          selected={selectedPlanMobile}
          setSelected={setSelectedPlanMobile}
        />
      </div>

      {/* PRICING CARD */}
      <div className="mt-[100px] w-full max-w-[1240px] m-auto px-[40px] max_xl:mt-[30px]">
        {allPlans?.length === 0 ? (
          <>
            <div className="flex justify-center gap-x-[20px]">
              <div className="rounded-lg overflow-hidden w-full max-w-[450px] h-96 cursor-pointer border px-5">
                <Skeleton width="100%" height={50} className="mt-4" />
                <Skeleton width="100%" height={40} className="mt-4" />
                <Skeleton width="100%" height={30} className="mt-12" />
                <Skeleton width="100%" height={30} className="mt-3" />
                <Skeleton width="100%" height={30} className="mt-3" />
              </div>
            </div>
          </>
        ) : (
          <>
            <PriceCardMobile
              plans={currentPlanCard}
              planFrequency={selected}
              selectedPlan={selectedPlan || {}}
              setSelectedPlan={() => {}}
              activeSubscription={activeSubscription}
            />
          </>
        )}
      </div>

      {/* TABLE */}
      <div className="mt-[100px] w-full max-w-[1240px] m-auto px-[20px] max_xl:mt-[30px]">
        <QueryTableMobile
          cellCss="!p-[15px]"
          tableData={featuresData}
          planId={selectedPlanMobile?.id}
        />
        <QueryTableMobile
          cellCss="!p-[15px]"
          onlyTitle={t("ResalaChat")}
          tableData={features2}
          planId={selectedPlanMobile?.id}
        />
        <QueryTableMobile
          cellCss="!p-[15px]"
          onlyTitle={t("Summarization")}
          tableData={featues3}
          planId={selectedPlanMobile?.id}
        />
        <QueryTableMobile
          cellCss="!p-[15px]"
          onlyTitle={t("WritingAssistant")}
          tableData={features4}
          planId={selectedPlanMobile?.id}
        />
        <QueryTableMobile
          cellCss="!p-[15px]"
          onlyTitle={t("SocialInteractions")}
          tableData={features5}
          planId={selectedPlanMobile?.id}
        />
        <QueryTableMobile
          cellCss="!p-[15px]"
          onlyTitle={t("Support")}
          tableData={features6}
          planId={selectedPlanMobile?.id}
        />
      </div>
    </>
  );
};

export default MobileViewContent;
