import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
// import Home from './Pages/Home/Home';
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import "swiper/css/bundle";
import { getProfileData } from "./Exe-api/apis";
import { profileInitials } from "./Pages/Extension/Components/utils";
import LoadingContext from "./Pages/Extension/Context/LoadingContext";
import ExeTour from "./Pages/Extension/ExeTour";
import Login from "./Pages/Extension/Login";
import AccountSetting from "./Pages/Extension/Pages/AccountSetting/AccountSetting";
import AuthCheck from "./Pages/Extension/Pages/AuthCheck/AuthCheck";
import DeleteAccount from "./Pages/Extension/Pages/DeleteAccount";
import ForgotPassword from "./Pages/Extension/Pages/ForgotPassword";
import OneDayLaterError from "./Pages/Extension/Pages/OneDayLaterError";
import ExistError from "./Pages/Extension/Pages/ReferEarn/ExistError";
import ReferEarn from "./Pages/Extension/Pages/ReferEarn/ReferEarn";
import Subscription from "./Pages/Extension/Pages/Subscriptions/Subscription";
import SupportHelp from "./Pages/Extension/Pages/Support/SupportHelp";
import Register from "./Pages/Extension/Register";
import ExeLayout from "./Pages/Extension/layout/ExeLayout";
import NotFound from "./Pages/NotFound";
import Pricing from "./Pages/Pricing/Pricing";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./Pages/TermsOfUse/TermsOfUse";
import ProtectedRoutes from "./ProtectedRoutes/ProtectedRoutes";
import { SubmitReferrer, getCount } from "./api";
// import Loader from "./assets/images/Navbar/Logo.gif";
import "./index.css";
import ScrollToTop from "./utils/ScrollTop";

// const Home = React.lazy(() => import("./Pages/Home/Home"));
import Home from "./Pages/Home/Home";
import WaitListContext from "./context/waitlistContext";
import ProfileContext from "./context/profileContext";
import UninstallScreen from "./Pages/UninstallPage/UninstallScreen";
import UninstallSuccess from "./Pages/UninstallPage/UninstallSuccess";
import { t } from "i18next";
import ConnectEmail from "./Pages/Extension/ConnectEmail";

function App() {
  const { i18n } = useTranslation();

  const [referrer, setReferrer] = useState("");
  const [loading, setLoading] = useState({ isAccountsPage: false });
  const [waitlistCount, setWaitlistCount] = useState(undefined);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS || "");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const OnSubmit = async () => {
    try {
      const payload = {
        referral_url: document.referrer,
      };
      await SubmitReferrer(payload);
    } catch (error) {
      console.log("error", error);
    }
  };

  window.postMessage({ type: "REQUEST_AUTH_TOKEN" }, "*");

  useEffect(() => {
    if (document.referrer) {
      OnSubmit();
    }
    setReferrer(document.referrer);
    // You can also perform other actions based on the referrer URL here
  }, []);

  const initialData = {
    uuid: "",
    email: "",
    first_name: "",
    last_name: "",
    country_code: "",
    mobile_number: "",
    profile_picture: null,
    referral_code: "",
    is_password: false,
    google_account: [],
    facebook_account: [],
    apple_account: [],
  };

  const [userData, setUserData] = useState(initialData);

  const handleCount = () => {
    getCount()
      .then((response) => {
        setWaitlistCount(response.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fetchUserData = async () => {
    setLoading({ ...loading, isAccountsPage: true });
    const authTokenFirst = JSON.parse(localStorage.getItem("token"));
    try {
      const user_data = await getProfileData(`Bearer ${authTokenFirst}`);
      setUserData(user_data);
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading({ ...loading, isAccountsPage: false });
    }
  };

  useEffect(() => {
    fetchUserData();
    handleCount();
    console.log("29-10");
  }, []);

  if (!loading.isAccountsPage && !userData) {
    return <h1 className="text-ErrorText">{t("server_error")}</h1>;
  }

  const profilePicture = userData?.profile_picture;
  const Initials = profileInitials(userData?.first_name, userData?.last_name);

  return (
    <>
      {/* <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              background: "white",
            }}
          >
            <img width={300} height={300} src={Loader} alt="" />
          </div>
        }
      > */}
      <div className="anime-fade-in" id="main-app">
        <LoadingContext.Provider value={{ loading, setLoading }}>
          <WaitListContext.Provider value={{ waitlistCount, setWaitlistCount }}>
            <ProfileContext.Provider value={{ userData }}>
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<Home referrer={referrer} />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<TermsOfUse />} />
                <Route
                  path="/pricing"
                  element={<Pricing referrer={referrer} />}
                />
                <Route
                  path="/chrome/uninstall-feedback"
                  element={<UninstallScreen />}
                />
                <Route
                  path="/chrome/uninstall-success"
                  element={<UninstallSuccess />}
                />

                {/* Extension Routes */}
                <Route path="/auth/:authToken" element={<AuthCheck />} />
                <Route path="/login" element={<Login />} />
                <Route path="/email-connect" element={<ConnectEmail />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/tour" element={<ExeTour />} />
                <Route path="/delete" element={<DeleteAccount />} />
                <Route path="/account-error" element={<OneDayLaterError />} />

                <Route
                  path="/profile"
                  exact
                  element={
                    <ProtectedRoutes>
                      <ExeLayout
                        profile_picture={profilePicture}
                        initials={Initials}
                      >
                        <AccountSetting
                          userData={userData}
                          setUserData={setUserData}
                        />
                      </ExeLayout>
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path="/billing"
                  exact
                  element={
                    <ProtectedRoutes>
                      <ExeLayout
                        profile_picture={profilePicture}
                        initials={Initials}
                      >
                        <Subscription
                          initials={Initials}
                          profile_picture={profilePicture}
                        />
                      </ExeLayout>
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path="/invite"
                  exact
                  element={
                    <ProtectedRoutes>
                      <ExeLayout
                        profile_picture={profilePicture}
                        initials={Initials}
                      >
                        <ReferEarn />
                      </ExeLayout>
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path="/support"
                  exact
                  element={
                    <ProtectedRoutes>
                      <ExeLayout
                        profile_picture={profilePicture}
                        initials={Initials}
                      >
                        <SupportHelp />
                      </ExeLayout>
                    </ProtectedRoutes>
                  }
                />

                <Route path="/already-exist" exact element={<ExistError />} />
                <Route path="/*" element={<NotFound />} />
              </Routes>
            </ProfileContext.Provider>
          </WaitListContext.Provider>
        </LoadingContext.Provider>
      </div>

      {/* <Route
            path="/*"
            element={
              <LoadingContext.Provider value={{ loading, setLoading }}>
              <Setting />
              </LoadingContext.Provider>
            }
          /> */}
      {/* </Suspense> */}
    </>
  );
}

export default App;
