import React, { useEffect, useState } from "react";
// import ResalaLogo from '../assets/images/Navbar/Resala Logo.svg';
// import ResalaLogo from '../assets/images/Navbar/Nav_Logo3.gif';
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { authenticateUser, getProfileData } from "../Exe-api/apis";
import UserProfile from "../Pages/Extension/Components/UserProfile";
import { cn, profileInitials } from "../Pages/Extension/Components/utils";
import ResalaLogoArabic from "../assets/images/Navbar/Resala-animated-arb.gif";
import ResalaLogoNew from "../assets/images/Navbar/Resala-animated.gif";
import Menu from "../assets/images/Navbar/menu.svg";
import { ButtonFilled, UseResalaButton } from "./Button";

const Navbar = ({
  setIsMobileMenuOpen,
  activeLangauge,
  setActiveLangauge,
  langaugeList,
  handleClickScroll,
  onChangeFun,
  selectedLanguage,
  isModalOpen,
  setModalOpen,
  toggleModal,
  hideModal,
}) => {
  var d = new Date();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [userData, setUserData] = useState(undefined);

  const [MenuList, setMenuList] = useState([]);

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 500);

    // Cleanup the timer if the component unmounts before the delay
    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //   window.addEventListener("message", (event) => {
  //     if (
  //       event.data === "LOCAL_STORAGE_CHANGED" ||
  //       event.data === "LOGOUT_EXE"
  //     ) {
  //       valiadateUser();
  //     }
  //   });
  // }, []);

  useEffect(() => {
    valiadateUser();
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        valiadateUser();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const valiadateUser = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const res = await authenticateUser({ token: token });
      if (res.Error || res.detail || res.message) {
        setUserData(undefined);
        return;
      }
      try {
        const user_data = await getProfileData(`Bearer ${token}`);
        setUserData(user_data);
      } catch (err) {
        console.log("err", err);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const res = await authenticateUser({ token: token });
  //       if (res.Error || res.detail || res.message) {
  //         return;
  //       }
  //       try {
  //         const user_data = await getProfileData(`Bearer ${token}`);
  //         setUserData(user_data);
  //       } catch (err) {
  //         console.log("err", err);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, [location]);

  useEffect(() => {
    // Update lists when language changes
    setMenuList(getTranslatedList(t, activeLangauge));
  }, [t, activeLangauge]);

  return (
    <div className="z-[20] px-5 w-[100%] m-auto flex h-[80px] items-center max_md:px-[40px] max_sm:px-[20px]">
      <div className="F-JC-AI-CENTER w-full justify-between ">
        <div
          onClick={() => {
            if (location.pathname === "/") {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            } else {
              navigate("/");
            }
          }}
          className="anime-fade-in flex items-center justify-between cursor-pointer max_md:w-[100%]"
        >
          <div className="z-40 w-[140px] max_sm:w-[90px] ">
            <img
              draggable="false"
              src={
                i18n.language === "en"
                  ? ResalaLogoNew + "?" + d.getTime()
                  : ResalaLogoArabic
              }
              alt="Logo"
            />
          </div>
          <div className="hidden max_md:block">
            <div className="flex items-center gap-7 max_sm:gap-3">
              {/* <ButtonFilled
                title={t('SignUp')}
              /> */}
              <div
                className="cursor-pointer z-40"
                onClick={() => {
                  setIsMobileMenuOpen(true);
                }}
              >
                <img src={Menu} alt="Menu" />
              </div>
            </div>
          </div>
        </div>

        <div className="relative F-JC-AI-CENTER flex gap-x-[20px] max_md:hidden">
          {location.pathname === "/chrome/uninstall-feedback" ||
          location.pathname === "/chrome/uninstall-success" ? (
            <>
              <UseResalaButton title={t("ContinueToResala")} />
            </>
          ) : (
            <>
              {MenuList.map((menu, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      if (menu.langauge) {
                        if (i18n.language === "en") {
                          onChangeFun("ar");
                          setActiveLangauge("English");
                        } else {
                          onChangeFun("en");
                          setActiveLangauge("عربي");
                        }
                      }
                      if (menu.name === t("Features")) {
                        // navigate('/');
                        handleClickScroll("featureScrollId");
                      }
                      if (menu.name === t("Pricing")) {
                        navigate("/pricing");
                      }
                      if (menu.name === t("HowItWorks")) {
                        handleClickScroll("HowItWorksId");
                      }
                    }}
                  >
                    <NavLink
                      className="z-[90] flex h-[50px] w-full items-center justify-end no-underline"
                      to={menu.link}
                    >
                      {({ isActive }) => (
                        <>
                          <div
                            className={`cursor-pointer text-TextSmall font-DMSansFont font-medium hover:text-LightPrimary ${
                              menu.link === "/register"
                                ? "text-LightPrimaryActive hover:text-LightPrimaryActive"
                                : "hover:text-duskwood text-LightTextParagraph"
                            }`}
                          >
                            {menu.name}
                          </div>
                        </>
                      )}
                    </NavLink>
                  </div>
                );
              })}
              {userData && typeof userData === "object" && (
                <>
                  <button onClick={() => navigate("/profile")}>
                    {userData.profile_picture ? (
                      <img
                        className="w-[40px] h-[40px] rounded-full object-cover"
                        src={userData.profile_picture}
                        alt="profile_picture"
                      />
                    ) : userData.first_name ? (
                      <UserProfile
                        width="40px"
                        height="40px"
                        fontSize="20px"
                        name={profileInitials(
                          userData.first_name,
                          userData.last_name
                        )}
                      />
                    ) : (
                      <>
                        <Skeleton width={40} height={40} />
                      </>
                    )}
                  </button>
                </>
              )}
              {(!userData || (userData && typeof userData === "string")) && (
                // <div>
                //   {/* <NavLink
                //     className="z-[90] flex h-[50px] w-full items-center justify-end no-underline"
                //     to={"/register"}
                //   > */}
                //   <NavLink
                //     className="z-[90] flex h-[50px] w-full items-center justify-end no-underline"
                //     to={"/register"}
                //   >
                //     {({ isActive }) => (
                //       <>
                //         <div
                //           className={cn(
                //             `cursor-pointer !text-LightPrimaryActive !hover:text-LightPrimaryActive text-TextSmall font-DMSansFont font-medium `
                //           )}
                //         >
                //           {t("SignUp")}
                //         </div>
                //       </>
                //     )}
                //   </NavLink>
                // </div>
                <div>
                  {/* <NavLink
                className="z-[90] flex h-[50px] w-full items-center justify-end no-underline"
                to={"/register"}
              > */}
                  <button
                    className="z-[90] flex h-[50px] w-full items-center justify-end no-underline"
                    onClick={toggleModal}
                  >
                    <div
                      className={`cursor-pointer text-LightPrimaryActive hover:text-DarkHover text-TextSmall font-DMSansFont font-medium `}
                    >
                      {t("SignUp")}
                    </div>
                  </button>
                </div>
              )}
              <div className={cn("z-[60]")}>
                <ButtonFilled
                  title={t("JoinWaitlist")}
                  onClick={toggleModal}
                  // onClick={() => {
                  //   navigate('/contact-us');
                  // }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const getTranslatedList = (t, activeLangauge) => {
  // Define your lists here
  const lists = [
    {
      name: t("Feature"),
      link: "/",
      langauge: false,
    },
    {
      name: t("HowItWorks"),
      link: "/",
      langauge: false,
    },
    {
      name: t("Pricing"),
      link: "/pricing",
      langauge: false,
    },
    {
      name: activeLangauge,
      link: "",
      langauge: true,
    },
  ];

  return lists;
};

export default Navbar;
