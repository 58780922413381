import React, { useState } from "react";
import Accordin from "../../Components/Support/Accordin";
import SupportCard, { SupportIcon } from "../../Components/Support/SupportCard";
import SupportCardLayout, {
  EmailIcon,
  FAQIcon,
} from "../../Components/Support/SupportCardLayout";
import TitleComp, { BulbIcon } from "../../Components/TitleComp";
import { useNavbar } from "../../customHook/useNavbar";
import ModalBaseLayout from "../../Components/Modal";
import GetInTouch from "./GetInTouch";
import { useTranslation } from "react-i18next";

const SupportHelp = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  useNavbar(t("help_support"));
  const isArabic = language === "ar";
  const [isOpen, setIsOpen] = useState(undefined);
  const [isGetInTouch, setIsGetInTouch] = useState(false);

  const openGetTouchModal = () => setIsGetInTouch(true);
  const closeGetTouchModal = () => setIsGetInTouch(false);

  const Arr = [
    {
      title: t("left_query_count"),
      content: (
        <div className="max-w-[922px]">
          <span className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
            {t("do_in_two_ways")}
          </span>
          <p className="text-gray-500 text-base font-semibold font-DMSansFont leading-normal">
            {t("from_websites")}{" "}
            <span className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
              {t("log_into_account")}
            </span>
          </p>

          <p className="text-gray-500 text-base font-semibold font-DMSansFont leading-normal">
            {t("from_exe_tab")}
            <span className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
              {" "}
              {t("open_exe")}
            </span>
          </p>
        </div>
      ),
    },
    {
      title: t("faq1"),
      content: (
        <p className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
          {t("faq1_desc")}
        </p>
      ),
    },
    {
      title: t("faq2"),
      content: (
        <p className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
          {t("faq2_desc")}
        </p>
      ),
    },
    {
      title: t("faq3"),
      content: (
        <p className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
          {t("faq3_desc")}
        </p>
      ),
    },
    {
      title: t("faq4"),
      content: (
        <div className="w-[922px]">
          <p className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
            {t("faq4_desc")}
          </p>
          <br />
          <p className="text-gray-500 text-base font-semibold font-DMSansFont leading-normal">
            1. {t("standard_queries")}:
          </p>
          <p className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
            {" "}
            {t("faq4_desc_1")}
          </p>
          <br />
          <p className="text-gray-500 text-base font-semibold font-DMSansFont leading-normal">
            2. {t("advanced_queries")}:
          </p>
          <p className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
            {" "}
            {t("faq4_desc_2")}
          </p>
        </div>
      ),
    },
    {
      title: t("faq5"),
      content: (
        <p className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
          {t("faq5_desc")}
        </p>
      ),
    },
    {
      title: t("faq6"),
      content: (
        <p className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
          {t("faq6_desc")}
        </p>
      ),
    },
    {
      title: t("faq7"),
      content: (
        <p className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
          {t("faq7_desc")}
        </p>
      ),
    },
    {
      title: t("faq8"),
      content: (
        <p className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
          {t("faq8_desc")}
        </p>
      ),
    },
    {
      title: t("faq9"),

      content: (
        <div className="w-[922px]">
          <p className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
            {t("faq9_desc_1")}
          </p>
          <br />
          <p className="text-gray-500 text-base font-semibold font-DMSansFont leading-normal">
            {t("faq9_desc_2")}
          </p>
          <p className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
            {" "}
            {t("faq9_desc_3")}
          </p>
          <p className="text-gray-500 text-base font-semibold font-DMSansFont leading-normal">
            {t("faq9_desc_4")}
          </p>
          <p className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
            {" "}
            {t("faq9_desc_5")}
          </p>
          <p className="text-gray-500 text-base font-semibold font-DMSansFont leading-normal">
            {t("faq9_desc_6")}{" "}
          </p>
          <p className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
            {t("faq9_desc_7")}
          </p>
          <br />
          <p className="text-gray-500 text-base font-normal font-DMSansFont leading-normal">
            {t("faq9_desc_8")}
          </p>
        </div>
      ),
    },
  ];
  return (
    <>
      {/* <Header title="Help & Support"Initials="VS"/> */}
      <div className="w-full">
        <div className="px-20 py-10 flex flex-col">
          <div className="mb-[40px]">
            <TitleComp icon={<SupportIcon />} title={t("Support")}>
              <button className="">
                <a
                  href={
                    isArabic
                      ? "https://resalaai.tawk.help/ar"
                      : "https://resalaai.tawk.help"
                  }
                  target="_new"
                  className="flex gap-x-[8px] items-center"
                  onClick={() => "window.location=another.html"}
                >
                  <BulbIcon />
                  <p className="text-[16px] font-medium leading-[21px] text-LightPrimaryActive">
                    {t("knowledge_base")}
                  </p>
                </a>
              </button>
            </TitleComp>
          </div>
          <SupportCardLayout>
            <div className="flex justify-between">
              <SupportCard
                icon={<EmailIcon />}
                title={t("email")}
                desc={t("reach_via_email")}
                btnTitle={
                  <a
                    className="flex"
                    href="mailto:support@resala.ai"
                    onClick={() => "window.location=another.html"}
                  >
                    {t("email_us")}
                  </a>
                }
              />
              <SupportCard
                icon={<MessageIcon />}
                title={t("chat")}
                desc={t("reach_via_chat")}
                btnTitle={t("chat_with_us")}
                onClick={() => {
                  window.Tawk_API.toggle();
                }}
              />
              <SupportCard
                icon={<ChatWithUS />}
                title={t("submit_a_request")}
                desc={t("reach_via_report")}
                btnTitle={t("submit_request")}
                onClick={openGetTouchModal}
              />
            </div>
          </SupportCardLayout>
          <div className="mt-[40px] mb-[20px]">
            <TitleComp icon={<FAQIcon />} title={t("faq")} />
          </div>
          <SupportCardLayout padding={"0px 40px"}>
            {Arr.map((itm, idx) => (
              <Accordin
                idx={idx}
                key={idx}
                isOpen={isOpen}
                title={itm.title}
                content={itm.content}
                setIsOpen={setIsOpen}
                total={Arr.length}
              />
            ))}
          </SupportCardLayout>
        </div>
      </div>

      {/* UPDATE PROFILE */}
      <ModalBaseLayout
        title=""
        isPreventOutsideClick
        isOpen={isGetInTouch}
        setClose={closeGetTouchModal}
      >
        <GetInTouch onClose={closeGetTouchModal} />
      </ModalBaseLayout>
    </>
  );
};

export default SupportHelp;

export const DownChevron = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.69 9.17969H17.92C18.433 9.17969 18.6939 9.80153 18.3349 10.1676C18.3346 10.168 18.3342 10.1684 18.3338 10.1688L15.1264 13.3761L13.1564 15.3461C12.5217 15.9809 11.4883 15.9809 10.8535 15.3461L5.67355 10.1661C5.3076 9.80019 5.56675 9.17969 6.07999 9.17969H11.69Z"
      fill="#8C90A5"
      stroke="#8C90A5"
    />
  </svg>
);

export const UpChevron = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6897 9.18018H17.9197C18.4328 9.18018 18.6936 9.80202 18.3347 10.1681C18.3343 10.1685 18.334 10.1689 18.3336 10.1693L15.1262 13.3766L13.1562 15.3466C12.5215 15.9814 11.488 15.9814 10.8533 15.3466L5.6733 10.1666C5.30736 9.80068 5.56651 9.18018 6.07975 9.18018H11.6897Z"
      fill="#19224C"
      stroke="#19224C"
    />
  </svg>
);

const MessageIcon = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0002 38.0154C18.8502 38.0154 17.7668 37.432 17.0002 36.4154L14.5002 33.082C14.4502 33.0154 14.2502 32.932 14.1668 32.9154H13.3335C6.3835 32.9154 2.0835 31.032 2.0835 21.6654V13.332C2.0835 5.96536 5.96683 2.08203 13.3335 2.08203H26.6668C34.0335 2.08203 37.9168 5.96536 37.9168 13.332V21.6654C37.9168 29.032 34.0335 32.9154 26.6668 32.9154H25.8335C25.7002 32.9154 25.5835 32.982 25.5002 33.082L23.0002 36.4154C22.2335 37.432 21.1502 38.0154 20.0002 38.0154ZM13.3335 4.58203C7.36683 4.58203 4.5835 7.36537 4.5835 13.332V21.6654C4.5835 29.1987 7.16683 30.4154 13.3335 30.4154H14.1668C15.0168 30.4154 15.9835 30.8987 16.5002 31.582L19.0002 34.9154C19.5835 35.682 20.4168 35.682 21.0002 34.9154L23.5002 31.582C24.0502 30.8487 24.9168 30.4154 25.8335 30.4154H26.6668C32.6335 30.4154 35.4168 27.632 35.4168 21.6654V13.332C35.4168 7.36537 32.6335 4.58203 26.6668 4.58203H13.3335Z"
      fill="#6D77A0"
    />
    <path
      d="M20.0002 20.0013C19.0668 20.0013 18.3335 19.2513 18.3335 18.3346C18.3335 17.418 19.0835 16.668 20.0002 16.668C20.9168 16.668 21.6668 17.418 21.6668 18.3346C21.6668 19.2513 20.9335 20.0013 20.0002 20.0013Z"
      fill="#6D77A0"
    />
    <path
      d="M26.6667 20.0013C25.7333 20.0013 25 19.2513 25 18.3346C25 17.418 25.75 16.668 26.6667 16.668C27.5833 16.668 28.3333 17.418 28.3333 18.3346C28.3333 19.2513 27.6 20.0013 26.6667 20.0013Z"
      fill="#6D77A0"
    />
    <path
      d="M13.3332 20.0013C12.3998 20.0013 11.6665 19.2513 11.6665 18.3346C11.6665 17.418 12.4165 16.668 13.3332 16.668C14.2498 16.668 14.9998 17.418 14.9998 18.3346C14.9998 19.2513 14.2665 20.0013 13.3332 20.0013Z"
      fill="#6D77A0"
    />
  </svg>
);

export const ChatWithUS = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.7835 36.4515C29.2668 36.4515 28.7501 36.3182 28.2835 36.0349L21.6001 32.0682C20.9001 32.0515 20.2001 32.0016 19.5335 31.9016C19.0835 31.8349 18.7001 31.5348 18.5335 31.1015C18.3668 30.6682 18.4501 30.2015 18.7501 29.8515C19.8501 28.5682 20.4168 27.0349 20.4168 25.4015C20.4168 21.3682 16.8668 18.0848 12.5001 18.0848C10.8668 18.0848 9.30012 18.5349 7.98345 19.4016C7.61678 19.6349 7.16679 19.6682 6.76679 19.4849C6.38346 19.3015 6.10014 18.9348 6.05014 18.5015C6.00014 18.0348 5.9668 17.5682 5.9668 17.0849C5.9668 8.81821 13.1335 2.10156 21.9335 2.10156C30.7335 2.10156 37.9001 8.81821 37.9001 17.0849C37.9001 21.6182 35.8001 25.7849 32.1001 28.6349L32.6668 33.1683C32.8001 34.3016 32.3001 35.3683 31.3501 35.9849C30.8835 36.2849 30.3335 36.4515 29.7835 36.4515ZM21.9168 29.5515C22.1501 29.5348 22.3835 29.6016 22.5835 29.7349L29.5668 33.8849C29.7501 34.0016 29.9001 33.9516 30.0001 33.8849C30.0835 33.8349 30.2168 33.7016 30.1835 33.4682L29.5335 28.2015C29.4835 27.7348 29.6835 27.2849 30.0501 27.0182C33.4501 24.6349 35.4001 21.0015 35.4001 17.0515C35.4001 10.1682 29.3668 4.5682 21.9335 4.5682C14.7835 4.5682 8.91679 9.76826 8.48345 16.3016C9.73345 15.8183 11.0835 15.5516 12.4835 15.5516C18.2335 15.5516 22.9001 19.9515 22.9001 25.3682C22.9168 26.8348 22.5668 28.2515 21.9168 29.5515Z"
      fill="#6D77A0"
    />
    <path
      d="M7.63296 37.9164C7.19962 37.9164 6.78295 37.7997 6.39961 37.5497C5.64961 37.0664 5.24963 36.2331 5.34963 35.3497L5.68296 32.783C3.43296 30.9497 2.09961 28.233 2.09961 25.383C2.09961 22.133 3.79963 19.0997 6.64963 17.283C8.36629 16.1664 10.3996 15.5664 12.5163 15.5664C18.2663 15.5664 22.9329 19.9663 22.9329 25.383C22.9329 27.583 22.1329 29.7497 20.6663 31.4664C18.7829 33.7497 15.9663 35.083 12.8663 35.183L8.79962 37.5997C8.43295 37.8164 8.03296 37.9164 7.63296 37.9164ZM12.4996 18.0664C10.8663 18.0664 9.29961 18.5164 7.98294 19.383C5.84961 20.7497 4.58295 22.983 4.58295 25.383C4.58295 27.6997 5.71629 29.8164 7.71629 31.1831C8.09963 31.4497 8.29961 31.8997 8.24961 32.3663L7.88295 35.2164L11.8663 32.8497C12.0663 32.7331 12.283 32.6663 12.4996 32.6663C14.9496 32.6663 17.2663 31.6164 18.733 29.8331C19.833 28.5331 20.4163 26.9996 20.4163 25.3663C20.4163 21.3496 16.8663 18.0664 12.4996 18.0664Z"
      fill="#6D77A0"
    />
  </svg>
);
