import axios from "axios";
import { BASE_URL } from "../../config";

export const emailCheck = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}/auth/email_check`, payload);
    return res.data.Message;
  } catch (error) {
    return error?.response;
  }
};

export const loginUser = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}/auth/login`, payload);
    return res.data;
  } catch (error) {
    return error?.response;
  }
};

export const OtpMailCheck = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}/auth/send_otp_email`, payload);
    return res.data;
  } catch (error) {
    return error?.response;
  }
};

export const OtpMobileCheck = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}/auth/send_otp`, payload);
    return res.data;
  } catch (error) {
    return error?.response;
  }
};

export const OtpCheck = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}/auth/otp_verification`, payload);
    return res.data;
  } catch (error) {
    return error?.response;
  }
};

export const forgotPassword = async (payload) => {
  try {
    const res = await axios.patch(`${BASE_URL}/auth/forgot_password`, payload);
    return res.data;
  } catch (error) {
    return error?.response;
  }
};

export const loginWithGoogle = async (payload) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/auth/signup_with_google`,
      payload
    );
    return res.data;
  } catch (error) {
    return error?.response;
  }
};

export const loginWithFacebook = async (payload) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/auth/signup_with_facebook`,
      payload
    );
    return res.data;
  } catch (error) {
    return error?.response;
  }
};

export const loginWithApple = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}/auth/signup_with_apple`, payload);
    return res.data;
  } catch (error) {
    return error?.response;
  }
};

export const alreadyRegistered = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}/auth/check_email`, payload);
    return res.data;
  } catch (error) {
    return error?.response;
  }
};

export const registerUser = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}/auth/signup`, payload);
    return res.data;
  } catch (error) {
    return error?.response;
  }
};

export const registerFBSendOtp = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}/auth/signup_otp`, payload);
    return res.data;
  } catch (error) {
    return error?.response;
  }
};

export const checkFbAccExistById = async (payload) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/auth/check_facebook_account`,
      payload
    );
    return res.data;
  } catch (error) {
    return error?.response;
  }
};
