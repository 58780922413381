import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationsInEng from "../Locales/en/translation.json";
import translationsInArabic from "../Locales/ar/translation.json";
import translationsInSpanish from "../Locales/es/translation.json";
import translationsInHindi from "../Locales/hi/translation.json";
import translationsInPortugeus from "../Locales/pt/translation.json";
import translationsInChinese from "../Locales/zh/translation.json";
import translationsInRussian from "../Locales/ru/translation.json";

// the translations
const resources = {
  en: {
    translation: translationsInEng,
  },
  ar: {
    translation: translationsInArabic,
  },
  es: {
    translation: translationsInSpanish,
  },
  hi: {
    translation: translationsInHindi,
  },
  pt: {
    translation: translationsInPortugeus,
  },
  zh: {
    translation: translationsInChinese,
  },
  ru: {
    translation: translationsInRussian,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources, // resources are important to load translations for the languages.
    lng: localStorage.getItem("lang"), // It acts as default language. When the site loads, content is shown in this language.
    debug: false,
    fallbackLng: "en", // use de if selected language is not available
    interpolation: { escapeValue: false },
    ns: "translation", // namespaces help to divide huge translations into multiple small files.
    defaultNS: "translation",
  });

export default i18n;
