import React, { useEffect, useRef, useState } from "react";
import { DownChevron, UpChevron } from "../../Pages/Support/SupportHelp";
import { cn } from "../utils";
import { useTranslation } from "react-i18next";

const Accordin = ({ idx, isOpen, setIsOpen, title, content, total }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState(null); // Start with null to apply height dynamically
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isActive = isOpen === idx;
  const isArabic = language === "ar";

  useEffect(() => {
    setHeight(isActive ? `${contentRef.current.scrollHeight}px` : "0px");

    const transitionEnd = () => {
      if (isActive) setHeight("auto");
    };

    const contentCurrent = contentRef.current;
    contentCurrent.addEventListener("transitionend", transitionEnd);
    return () =>
      contentCurrent.removeEventListener("transitionend", transitionEnd);
  }, [isOpen, idx, isActive]);

  return (
    <div
      className={`w-full ${idx !== total - 1 && "border-b"}  overflow-hidden`}
    >
      <button
        className={cn("pt-[40px] w-full", {
          "py-[40px]": !isActive,
        })}
        onClick={() => setIsOpen(isActive ? null : idx)}
      >
        <div className="flex justify-between items-center w-full">
          <p
            className={cn(
              "text-[20px] leading-[26px] text-LightTextParagraph",
              isArabic ? "text-right" : "text-left",
              { "text-LightPrimary": isActive }
            )}
          >
            {title}
          </p>
          <div
            className={cn("rotate-0 transition-all duration-200", {
              "rotate-180": isActive,
            })}
          >
            {isActive ? <UpChevron /> : <DownChevron />}
          </div>
        </div>
      </button>
      <div
        ref={contentRef}
        style={{ height }}
        className={cn(
          "transition-height duration-500 ease-in-out overflow-hidden"
        )}
      >
        <div className={cn("mt-[12px] flex items-start pb-[40px]", {})}>
          {content}
          {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
        </div>
      </div>
    </div>
  );
};

export default Accordin;
