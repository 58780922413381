import { useContext, useState } from "react";
import { ProfileIcon } from "../../Icons/Icons";
import {
  DeviderLine,
  SettingCard,
  SettingRow,
} from "../../Pages/AccountSetting/SettingRow";
import { ButtonExeFilled, ButtonOutLine } from "../Button";
import TitleComp from "../TitleComp";
import UserProfile from "../UserProfile";
import ModalBaseLayout from "../Modal";
import UpdateProfile from "./UpdateProfile";
import UpdateEmail from "./UpdateEmail";
import UpdateMobile from "./UpdateMobile";
import UpdatePassword from "./UpdatePassword";
import { cn, profileInitials } from "../utils";
import SetNewPass from "./SetNewPass";
import { useDetectCountry } from "../../customHook/useDetectCountry";
import LoadingContext from "../../Context/LoadingContext";
import Skeleton from "react-loading-skeleton";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export const ProfileSection = ({ userData, setUserData }) => {
  const {
    uuid,
    email,
    first_name,
    last_name,
    country_code,
    mobile_number,
    profile_picture,
    referral_code,
    is_password,
  } = userData;
  const {
    loading: { isAccountsPage },
  } = useContext(LoadingContext);

  const {
    i18n: { language },
  } = useTranslation();
  const isArabic = language === "ar";

  const userCountry = useDetectCountry();
  const [isSetPassword, setIsSetPassword] = useState(false);
  const [isUpdateProfile, setIsUpdateProfile] = useState(false);
  const [isEmailUpdate, setIsEmailUpdate] = useState(false);
  const [isMobileModal, setIsMobileModal] = useState(false);

  const [isSetPass, setIsSetPass] = useState(false);

  const openSetPasswordModal = () => setIsSetPass(true);
  const closeSetPasswordModal = () => setIsSetPass(false);

  const openPasswordModal = () => setIsSetPassword(true);
  const closePasswordModal = () => setIsSetPassword(false);

  const openProfileModal = () => setIsUpdateProfile(true);
  const closeProfileModal = () => setIsUpdateProfile(false);

  const openEmailModal = () => setIsEmailUpdate(true);
  const closeEmailModal = () => setIsEmailUpdate(false);

  const openMobileModal = () => setIsMobileModal(true);
  const closeMobileModal = () => setIsMobileModal(false);

  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const Initials = profileInitials(first_name, last_name);
  const FullName = first_name + " " + last_name;
  const mobileNo = `${country_code} ${mobile_number}`;

  return (
    <>
      <TitleComp icon={<ProfileIcon />} title={t("profile")} />
      <SettingCard>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-[30px]">
            {isAccountsPage ? (
              <Skeleton circle height={79} width={79} />
            ) : profile_picture ? (
              // <img
              //   className="w-[80px] h-[80px] rounded-full object-cover"
              //   src={profile_picture}
              //   alt="profile_picture"
              // />
              <>
                {!isLoaded && <Skeleton height={79} width={79} circle />}
                <img
                  style={{
                    borderRadius: "50%",
                    display: isLoaded ? "block" : "none",
                  }}
                  className="w-[80px] h-[80px] rounded-full object-cover"
                  src={profile_picture}
                  alt="avatar"
                  onLoad={handleImageLoad}
                  onError={() => setIsLoaded(false)} // Optionally handle loading error
                />
              </>
            ) : (
              <UserProfile
                width="80px"
                height="80px"
                name={Initials}
                fontSize="34px"
              />
            )}
            <div>
              <p className="Text20Heading capitalize">
                {isAccountsPage ? (
                  <Skeleton height={24} width={140} />
                ) : (
                  FullName
                )}
              </p>
              <p className="text-[18px] font-DMSansFont font-medium text-LightTextParagraph">
                {isAccountsPage ? <Skeleton height={18} width={280} /> : uuid}
              </p>
            </div>
          </div>
          {isAccountsPage ? (
            <Skeleton height={40} width={134} />
          ) : (
            <ButtonOutLine
              onClick={openProfileModal}
              fontColor="#1678F2"
              width="90px"
              text={t("edit")}
              height={"40px"}
              hoverCss={
                "hover:bg-LightGray hover:!text-DarkHover hover:border-DarkHover"
              }
            />
          )}
        </div>
        <div className="w-[100%] h-[1px] bg-LightStrokPrimary"></div>
        <SettingRow
          title={
            isAccountsPage ? (
              <Skeleton height={18} width={140} />
            ) : (
              t("email_id")
            )
          }
          desc={isAccountsPage ? <Skeleton height={18} width={140} /> : email}
          action={
            isAccountsPage ? (
              <Skeleton height={40} width={134} />
            ) : (
              <ButtonOutLine
                hoverCss={
                  "hover:bg-LightGray hover:!text-DarkHover hover:border-DarkHover"
                }
                fontColor="#1678F2"
                width="90px"
                text={t("edit")}
                height={"40px"}
                onClick={openEmailModal}
              />
            )
          }
        />
        <DeviderLine />
        <SettingRow
          title={
            isAccountsPage ? (
              <Skeleton height={18} width={140} />
            ) : (
              t("mobile_number")
            )
          }
          desc={
            // <div dir="ltr">
            //   {
            isAccountsPage ? (
              <Skeleton height={18} width={140} />
            ) : mobile_number ? (
              <p
                dir="ltr"
                className={cn(isArabic ? "text-right" : "text-left")}
              >
                {mobileNo}
              </p>
            ) : (
              t("no_mobile_number_message")
            )
            // }
            // </div>
          }
          action={
            isAccountsPage ? (
              <Skeleton height={40} width={134} />
            ) : mobile_number ? (
              <ButtonOutLine
                hoverCss={
                  "hover:bg-LightGray hover:!text-DarkHover hover:border-DarkHover"
                }
                // isDisable={mobile_number ? false : true}
                onClick={openMobileModal}
                fontColor="#1678F2"
                width="90px"
                text={t("edit")}
                height={"40px"}
              />
            ) : (
              <ButtonOutLine
                hoverCss={
                  "hover:bg-LightGray hover:!text-DarkHover hover:border-DarkHover"
                }
                // isDisable={mobile_number ? false : true}
                onClick={openMobileModal}
                fontColor="#1678F2"
                width="90px"
                text={t("add")}
                height={"40px"}
              />
            )
          }
        />
        <DeviderLine />
        <SettingRow
          title={
            isAccountsPage ? (
              <Skeleton height={18} width={140} />
            ) : (
              t("password")
            )
          }
          desc={
            isAccountsPage ? (
              <Skeleton height={18} width={140} />
            ) : !is_password ? (
              t("set_password_login_message")
            ) : (
              <PasswordDots className="mt-[5px]" />
            )
          }
          action={
            isAccountsPage ? (
              <Skeleton height={40} width={134} />
            ) : !is_password ? (
              <ButtonExeFilled
                onClick={openSetPasswordModal}
                fontColor={"#FFFFFF"}
                width={"134px"}
                text={t("set_password")}
                hoverCss={"hover:!bg-DarkHover"}
              />
            ) : (
              <ButtonOutLine
                hoverCss={
                  "hover:bg-LightGray hover:!text-DarkHover hover:border-DarkHover"
                }
                onClick={openPasswordModal}
                fontColor="#1678F2"
                width="134px"
                text={t("change")}
                height={"40px"}
              />
            )
          }
        />
      </SettingCard>

      {/* UPDATE PROFILE */}
      <ModalBaseLayout
        title={t("change_information")}
        isOpen={isUpdateProfile}
        setClose={closeProfileModal}
      >
        <UpdateProfile
          first_name={first_name}
          last_name={last_name}
          profile_picture={profile_picture}
          updateUserData={setUserData}
          onClose={closeProfileModal}
        />
      </ModalBaseLayout>

      {/* UPDATE EMAIL */}
      <ModalBaseLayout
        isPreventOutsideClick
        title=""
        isOpen={isEmailUpdate}
        setClose={closeEmailModal}
      >
        <UpdateEmail
          onClose={closeEmailModal}
          email={email}
          updateUserData={setUserData}
        />
      </ModalBaseLayout>

      {/* UPDATE MOBILE */}
      <ModalBaseLayout
        isPreventOutsideClick
        overflow={"unset"}
        isOpen={isMobileModal}
        setClose={closeMobileModal}
      >
        <UpdateMobile
          isAddNew={!mobile_number}
          userCountry={userCountry}
          onClose={closeMobileModal}
          mobile_number={mobile_number}
          country_code={country_code}
          updateUserData={setUserData}
        />
      </ModalBaseLayout>

      {/* PASSWORD UPDATE */}
      <ModalBaseLayout
        isPreventOutsideClick
        // title={t("change_password")}
        isOpen={isSetPassword}
        setClose={closePasswordModal}
      >
        <UpdatePassword
          onClose={closePasswordModal}
          email={email}
          updateUserData={setUserData}
        />
      </ModalBaseLayout>

      {/* PASSWORD UPDATE */}
      <ModalBaseLayout
        isPreventOutsideClick
        title=""
        isOpen={isSetPass}
        setClose={closeSetPasswordModal}
      >
        <SetNewPass
          onClose={closeSetPasswordModal}
          email={email}
          updateUserData={setUserData}
        />
      </ModalBaseLayout>
    </>
  );
};

const PasswordDots = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="79"
    height="8"
    viewBox="0 0 79 8"
    fill="none"
  >
    <ellipse cx="4.01434" cy="4" rx="4.01434" ry="4" fill="#6D77A0" />
    <ellipse cx="14.0495" cy="4" rx="4.01434" ry="4" fill="#6D77A0" />
    <ellipse cx="24.0847" cy="4" rx="4.01434" ry="4" fill="#6D77A0" />
    <ellipse cx="34.1237" cy="4" rx="4.01434" ry="4" fill="#6D77A0" />
    <ellipse cx="44.1589" cy="4" rx="4.01434" ry="4" fill="#6D77A0" />
    <ellipse cx="54.194" cy="4" rx="4.01434" ry="4" fill="#6D77A0" />
    <ellipse cx="64.2292" cy="4" rx="4.01434" ry="4" fill="#6D77A0" />
    <ellipse cx="74.2643" cy="4" rx="4.01434" ry="4" fill="#6D77A0" />
  </svg>
);
