import React, { useEffect, useRef, useState } from "react";
import ResalaLogo from "../../assets/images/Navbar/Resala Logo.svg";
import ResalaLogoArabic from "../../assets/images/Navbar/Resala Logo - Arabic.svg";
import { NavLink, useNavigate } from "react-router-dom";
import CloseIcon from "../../assets/images/Navbar/CloseIcon.svg";
import { useTranslation } from "react-i18next";
import { ButtonFilled } from "../../Components/Button";
import { cn, profileInitials } from "../Extension/Components/utils";
import { authenticateUser, getProfileData } from "../../Exe-api/apis";
import UserProfile from "../Extension/Components/UserProfile";
import { langHelper } from "../Extension/Pages/AuthCheck/AuthCheck";

const MobileMenu = ({
  setIsMobileMenuOpen,
  nodeRef,
  outsideRef,
  activeLangauge,
  setActiveLangauge,
  langaugeList,
  handleClickScroll,
  onChangeFun,
  toggleModal,
}) => {
  const navigate = useNavigate();
  const [isLaungaugeOpen, setIsLaungaugeOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const outsideRefLang = useRef();
  const [userData, setUserData] = useState(undefined);

  useEffect(() => {
    valiadateUser();
  }, []);

  const valiadateUser = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const res = await authenticateUser({ token: token });
      if (res.Error || res.detail || res.message) {
        setUserData(undefined);
        return;
      }
      try {
        const user_data = await getProfileData(`Bearer ${token}`);
        setUserData(user_data);
      } catch (err) {
        console.log("err", err);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [MenuList, setMenuList] = useState([
    {
      name: t("Feature"),
      link: "/",
      langauge: false,
    },
    {
      name: t("HowItWorks"),
      link: "/",
      langauge: false,
    },
    {
      name: t("Pricing"),
      link: "/pricing",
      langauge: false,
    },
    {
      name: activeLangauge,
      link: "",
      langauge: true,
    },
  ]);
  useEffect(() => {
    const updatedMenuList = MenuList.map((item) => {
      if (item.langauge) {
        return { ...item, name: activeLangauge };
      }
      return item;
    });

    setMenuList(updatedMenuList);
  }, [activeLangauge]);

  // useOnClickOutside(outsideRefLang, () => setIsLaungaugeOpen(false));
  return (
    <>
      <div
        ref={nodeRef}
        className="z-[999999] fixed top-0 flex h-screen w-[100%] overflow-hidden"
      >
        <div ref={outsideRef} className="w-full bg-white shadow-lg ">
          <div className="F-JC-AI-CENTER h-[80px] justify-between px-[40px] max_sm:px-[20px] ">
            <div className="w-[90px] ">
              <img
                draggable="false"
                src={i18n.language === "ar" ? ResalaLogoArabic : ResalaLogo}
                alt="Logo"
              />
            </div>
            <div className="flex items-center gap-3">
              <div className="">
                <ButtonFilled title={t("JoinWaitlist")} onClick={toggleModal} />
              </div>

              <div
                className="cursor-pointer "
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <img draggable="false" src={CloseIcon} alt="" />
              </div>
            </div>
          </div>

          <div className="w-full bg-white h-[calc(100vh-70px)] z-[100] overflow-y-scroll">
            {MenuList.map((menu, index) => (
              <React.Fragment key={index}>
                <NavLink
                  className={`flex w-full items-center border-[#00000033] no-underline`}
                  to={menu.link}
                >
                  {({ isActive }) => (
                    <div className="flex w-full flex-col">
                      <div
                        onClick={() => {
                          if (menu.langauge) {
                            if (i18n.language === "en") {
                              setIsMobileMenuOpen(false);
                              onChangeFun("ar");
                              setActiveLangauge("English");
                            } else {
                              setIsMobileMenuOpen(false);
                              onChangeFun("en");
                              setActiveLangauge("عربي");
                            }
                          }
                          if (menu.name === t("Features")) {
                            handleClickScroll("featureScrollId");
                            setIsMobileMenuOpen(false);
                          }
                          if (menu.name === t("HowItWorks")) {
                            handleClickScroll("HowItWorksId");
                            setIsMobileMenuOpen(false);
                          }
                        }}
                        className="flex gap-2 items-center w-full font-DMSansFont font-medium py-4 px-[50px] max_sm:px-[30px] text-LightTextParagraph text-TextMedium hover:bg-LightGray"
                      >
                        <div
                          className={cn(`cursor-pointer`, {
                            "text-LightPrimaryActive hover:text-LightPrimaryActive":
                              menu.link === "/register",
                          })}
                        >
                          {menu.name}
                        </div>
                      </div>
                    </div>
                  )}
                </NavLink>
              </React.Fragment>
            ))}
            {!userData && (
              <NavLink
                className={`flex w-full items-center border-[#00000033] no-underline`}
                to={"/register"}
              >
                {({ isActive }) => (
                  <div className="flex w-full flex-col">
                    <div
                      onClick={() => {}}
                      className="flex gap-2 items-center w-full font-DMSansFont font-medium py-4 px-[50px] max_sm:px-[30px] text-LightTextParagraph text-TextMedium hover:bg-LightGray"
                    >
                      <div
                        className={cn(
                          `cursor-pointer`,
                          "text-LightPrimaryActive hover:text-LightPrimaryActive"
                        )}
                      >
                        {t("SignUp")}
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
            )}
            {userData && (
              <div className="py-4 px-[30px]">
                <button onClick={() => navigate("/profile")}>
                  {userData.profile_picture ? (
                    <img
                      className="w-[40px] h-[40px] rounded-full object-cover"
                      src={userData.profile_picture}
                      alt="profile_picture"
                    />
                  ) : (
                    <UserProfile
                      width="40px"
                      height="40px"
                      fontSize="20px"
                      name={profileInitials(
                        userData.first_name,
                        userData.last_name
                      )}
                    />
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
