import React, { useEffect, useMemo, useState } from "react";
import { bannerDescription } from "../../Pricing/jsons";
import Header from "../Components/Header";
import ModalBaseLayout from "../Components/Modal";
import GetDiscount from "../Components/Subscription/GetDiscount";
import { AddBanner } from "../Pages/Subscriptions/AdBanner";
import Sidebar from "./Sidebar";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { cn } from "../Components/utils";
import useSidebar from "../../../hooks/useSidebar";

const ExeLayout = ({ children, profile_picture, initials }) => {
  const isOfferAvailable = true;
  const {
    i18n: { language },
  } = useTranslation();
  const [isDiscountModal, setIsDiscountModal] = useState(false);
  const { isCollapsed, toggleSidebar } = useSidebar();
  const openDiscountModal = () => setIsDiscountModal(true);
  const closeDiscountModal = () => setIsDiscountModal(false);
  const dummyData = {
    is_discount: true,
    discount: 50,
  };

  const [discountData, setDiscountData] = useState(undefined);

  // const getDiscountData = async () => {
  //   try {
  //     const response = await getDiscount();
  //     setDiscountData(response);
  //   } catch (err) {
  //     console.log("error", err);
  //   }
  // };

  // useEffect(() => getDiscountData(), []);

  const isArabic = language === "ar";

  return (
    <>
      <div id="dashboard-root" className="w-full m-auto flex h-screen">
        <div
          className={cn(
            "relative transition-all duration-100",
            isCollapsed ? "w-[75px]" : "w-[255px]",
            isArabic
              ? "border-l border-l-LightStrokPrimary"
              : "border-r border-r-LightStrokPrimary"
          )}
        >
          <Sidebar isArabic={isArabic} isCollapsed={isCollapsed} />
          <CollapseArrow
            isArabic={isArabic}
            isCollapsed={isCollapsed}
            onClick={toggleSidebar}
          />
        </div>
        <div
          id="content-main"
          className="w-[calc(100%-254px)] max-w-[1190px] m-auto"
        >
          {discountData?.is_discount && (
            <AddBanner
              className=""
              title={`<span>
                  GET <br /> ${discountData.discount}% OFF
                </span>`}
              description={bannerDescription}
              onClick={openDiscountModal}
            />
          )}
          <Header
            title={t("account_setting")}
            Initials={initials}
            profile_picture={profile_picture}
          />
          <div
            style={{
              height: discountData?.is_discount
                ? "calc(100vh - 200px)"
                : "calc(100vh - 80px)",
            }}
            className="overflow-y-scroll"
          >
            {children}
          </div>
        </div>
      </div>

      {/* isDiscountModal */}
      <ModalBaseLayout
        isPreventOutsideClick
        overflow={"hidden"}
        title=""
        isOpen={isDiscountModal}
        setClose={closeDiscountModal}
      >
        <GetDiscount setClose={closeDiscountModal} />
      </ModalBaseLayout>
    </>
  );
};

export default ExeLayout;

const CollapseArrow = ({ isCollapsed, isArabic, ...props }) => (
  <button
    className={cn(
      "absolute top-[20px] flex h-[32px] w-[32px] items-center justify-center rounded-full border border-StrokePrimary bg-white text-Primary ",
      isArabic ? "left-0 translate-x-[-50%]" : "right-0 translate-x-[50%]"
    )}
    {...props}
  >
    <DownArrow
      className={cn(
        "min-w-[20px] text-LightPrimaryActive  transition-all duration-100",
        !isArabic
          ? isCollapsed
            ? "-rotate-90"
            : "rotate-90"
          : isCollapsed
          ? "rotate-90"
          : "-rotate-90"
        // isArabic ? "-rotate-90" : "rotate-90",
        // isCollapsed && !isArabic ? "rotate-90" : "-rotate-90"
      )}
    />
  </button>
);

export const DownArrow = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M13.2802 5.9668L8.93355 10.3135C8.42021 10.8268 7.58021 10.8268 7.06688 10.3135L2.72021 5.9668"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// import React from "react";
// import Sidebar from "./Sidebar";
// import Header from "../Components/Header";

// const ExeLayout = ({ children, offerSection, profile_picture, initials }) => {
//   return (
//     <>
//       <div className="w-full m-auto flex h-screen">
//         <div className="w-[254px] border-r border-r-LightStrokPrimary">
//           <Sidebar />
//         </div>
//         {/* <div className="w-[calc(100%-254px)] max-w-[1190px] m-auto border-r border-r-LightStrokPrimary"> */}
//         <div id="content-main" className="w-full">
//           {offerSection && offerSection}
//           <div className="w-[calc(100%-254px)] max-w-[1190px] m-auto relative">
//             <Header
//               title="Account Settings"
//               Initials={initials}
//               profile_picture={profile_picture}
//             />

//             {children}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ExeLayout;
